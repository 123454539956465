<script>
  import {Container, Row, Col, Button, Card, CardBody, TabContent, TabPane} from "sveltestrap";
  import {navigate} from "svelte-routing";
  import {onMount, onDestroy} from "svelte";
  import {channels, projects, channelsAdditionalInfo, getChannelAdditionalInfo, user} from "../../helpers/store";
  import {layout} from "../../services/loaders";
  import {_, locale} from "svelte-i18n";
  import {timer, getRequest, alertNotifications, postRequest, promisifyStore} from "../../helpers/utils";
  import StoppedChannelModal from "../../Components/Modals/StoppedChannelModal.svelte";
  import Spinner from "../../Components/Spinners/SpinnerComponent.svelte";

  // Pages Components
  import ChannelInfo from "./ChannelInfo.svelte";
  // import ChannelQr from "./ChannelQr.svelte";
  import ChannelStatistic from "./ChannelStatistic.svelte";
  import ChannelSettings from "./ChannelSettings.svelte";
  // import MesagesQueue from "./MesagesQueue.svelte";
  import Templates from "./Templates.svelte";
  import ChannelInit from "./ChannelInit.svelte";
  import {defaultConst} from "../../common/constants";
  import {addToast} from "../../helpers/toast";

  export let params;

  let userChannels, channel = false, staticAlerts = [], statistic = [], fatal_error = false;
  let channelAdditionalInfo = {}, loading, lang, activeUser, activeTab, file = null, avatar, saving = false;
  let emulatorModalState = false;

  const updateChannel = async (id) => {
    layout(true);
    let channelTmp;
    if (userChannels) {
      channelTmp = userChannels.find((item) => {
        return item.id.toString() === id.toString();
      });
      if(channelTmp) {
          getChannelAdditionalInfo(channelTmp);
          channelTmp.status = "active";
          if (channelTmp.blocked) channel.status = "blocked";
          else if (channelTmp.trial > Date.now()) {
            channelTmp.status = "trial";
            channelTmp.trial = new Date(parseInt(channelTmp.trial));
          }
          channelTmp.project = $projects.find((item) => {
            return item.id === channelTmp.projectId;
          });
      }
    } else {
      channelTmp = false;
    }
    if(channelTmp) {
      if (!channel.stopped) {
        getStatistic(channelTmp);
      }
      channel = channelTmp;
      // maxStep = channel.paymentProfileId && 1 || channel.wabaInfo && 2 || 0;
    }
    await timer(300);
    layout(false);
    return channelTmp;
  };

  const updateChannelAdditionalInfo = data => {
    for (const dataKey in data) {
      channelAdditionalInfo[dataKey] = data[dataKey];
    }
  };

  onMount(async () => {
    promisifyStore(channels, channels => typeof channels !== 'undefined').then(data => {
      if (data) {
        let channelTmp = data.find((item) => {
          return item.id.toString() === params.id?.toString();
        });
        if (!channelTmp) {
          return navigate("/channels");
        }
        if (channelTmp?.srv === 'trial') {
          layout(false);
          return navigate('/trial', {replace: true})
        }
      }
    })
    if (localStorage.getItem("I18N_LANGUAGE")) {
      lang = localStorage.getItem("I18N_LANGUAGE");
    }
  });

  // afterUpdate(() => {
  //   if (emulatorModalState) {
  //     document.body.style.overflow = "hidden";
  //     if (window.innerWidth < 970) {
  //       document.querySelector("#tidio-chat").style.display = "none";
  //     }
  //   } else {
  //     document.body.style.overflow = "";
  //     document.querySelector("#tidio-chat").style.display = "block";
  //   }
  // })

  const unsub = channels.subscribe(async (value) => {
    userChannels = value;
    if(value) await updateChannel(params.id);
  });

  const unsub2 = channelsAdditionalInfo.subscribe(async (value) => {
    channelAdditionalInfo = value?.[params.id] ? value?.[params.id] : {};
    if (channelAdditionalInfo.id) {
      channelAdditionalInfo.id = channelAdditionalInfo.id.split("@").shift()
    }
    if (value?.[params.id]) {
      fatal_error = value?.[params.id].error || false;
      console.error('fatal_error', fatal_error, typeof fatal_error)
    }
  });

  const unsub3 = locale.subscribe((value) => {
    if (value) lang = value;
  });

  const unsub5 = user.subscribe((value) => {
    activeUser = value;
    staticAlerts = alertNotifications(activeUser)
  });

  function wabaInfo(field, mode) {
    try {
      let result;
      if (!channel.waba_info) return ''
      switch (field) {
        case 'ACCOUNT_NAME':
          result = channel.waba_info.waba_account.name;
          break;
        case 'PHONE':
          result = channel.waba_info.setup_info.phone_number;
          break;
        case 'PHONE_NAME':
          result = channel.waba_info.setup_info?.phone_name;
          break;
        case 'ACCOUNT_MODE':
          result = _.instant('WABA.INFO.STATUS.' + (channel.waba_info.terminated_at ? 'DELETION' : (channel.waba_info.account_mode ? 'LIVE' : 'DRAFT')));
          break;
        case 'WABA_ACCOUNT_STATUS':
          result = channel.waba_info.waba_account?.status;
          break;
        case 'EXTERNAL_WABA_CHANNEL':
          result = 'done';
          break;
        case 'MESSAGE_ON_BEHALF':
          result = channel.waba_info.waba_account.on_behalf_of_business_info ? channel.waba_info.waba_account.on_behalf_of_business_info?.status : 'unset';
          break;
        case 'DISPLAY_NAME_VERIFICATION':
          result = channel.waba_info.status;
          break;
        case 'FB_BUSINESS_VERIFICATION':
          result = channel.waba_info.waba_account.fb_account_status;
          break;
        case 'NUMBER_REGISTRATION':
          result = channel.waba_info.integration ? channel.waba_info.integration.state : 'unset';
          break;
        case 'CURRENT_LIMIT':
          result = channel.waba_info.current_limit ? $_('waba.limits.' + channel.waba_info.current_limit) : 'N/A';
          break;
        case 'CURRENT_QUALITY_RATING':
          result = channel.waba_info.current_quality_rating ? channel.waba_info.current_quality_rating : 'N/A';
          break;
      }
      if (mode === 'color') {
        result = wabaStatusColor(result);
      }
      return result;
    } catch (e) {}
  }

  function wabaStatusColor(status) {
    let color;
    switch (status) {
      case 'not_verified':
      case 'draft':
      case 'unknown':
      case 'unset':
        color = 'secondary';
        break;
      case 'failed':
      case 'account_violation':
      case 'error':
        color = 'danger';
        break;
      case 'unregistered':
      case 'unverified':
      case 'pending':
        color = 'warning';
        break;
      case 'ready':
      case 'running':
        color = 'primary';
        break;
      default:
        color = 'success';
    }
    return color;
  }

  async function getStatistic(activeChannel) {
    if (activeChannel?.apiUrl && activeChannel?.waba_info) {
      let result = await getRequest(`${activeChannel.apiUrl}statistics2`, {token: activeChannel.token});
      if (result) {
        statistic = result;
        if (statistic.length) {
          statistic.reverse();
          if (!activeTab) activeTab = 2;
        }
      }
    }
    if (!activeTab) activeTab = 1;
  }

  // const registerEmulatorToken = async () => {
  //   if (!addAdminLoading) {
  //     addAdminLoading = true;
  //     try {
  //       await registerUserInApp(false);
  //     } catch (e) {
  //       addAdminLoading = false;
  //     }
  //     addAdminLoading = false;
  //   }
 // };

  const reloadChannel = async () => {
    if (loading) return;
    loading = 'reload';
    let result = await postRequest(`${defaultConst.functionsUrl}/reloadChannel/`, {channelId: channel.id, userToken: activeUser.userToken});
    loading = false;
    if (!result || result.error) {
      await addToast({title: $_("error"), message: result.error || $_("something_went_wrong"), dismissible: true, timeout: 4_000});
    } else {
      await addToast({title: $_("saved")});
    }
  };

  const updateApiKey = async () => {
    if (loading) return;
    loading = 'update';
    let result = await postRequest(`${defaultConst.functionsUrl}/updateApiKey/`, {id: channel.id, token: channel.token});
    loading = false;
    if (!result || result.error) {
      await addToast({title: $_("error"), message: result.error || $_("something_went_wrong"), dismissible: true, timeout: 4_000});
    } else {
      await addToast({title: $_("updated")});
    }
  };

  const uploadAvatar = async () => {
    saving = true;
    try {
      let res = await postRequest(`${channel.apiUrl}me/`, {photo: avatar, token: channel.token});
      if (!res || res.error) {
        addToast({title: $_("error"), message: res.error || $_("something_went_wrong")});
      } else {
        if (res.status === 'ok') {
          addToast({title: $_("saved")});
        } else if (res.username) {
          addToast({title: $_("profile.this_name_is_already_taken")});
        }
        channelAdditionalInfo.photo = avatar;
        avatar = '';
        file = null;
      }
      saving = false;
    } catch (e) {
      console.error(e);
      addToast({title: $_("error"), message: $_("something_went_wrong"), dismissible: true, timeout: 4_000});
      saving = false;
    }
  };

  $: if (file !== null && file !== undefined) {
    if (!file.type.match('image.*')) {
      addToast({title: $_("profile.error.pleas_image")});
    } else {
      const reader = new FileReader();
      reader.onload = (function () {
        return function (e) {
          avatar = e.target.result;
        }
      })(file);
      reader.readAsDataURL(file);
    }
  }

  onDestroy(() => {
    unsub && unsub();
    unsub2 && unsub2();
    unsub3 && unsub3();
    unsub5 && unsub5();
  });
</script>

<div class="page-content px-0 px-sm-2">
    {#if channel.waba_info}
        {#if fatal_error}
          {#if channel.stopped}
            <StoppedChannelModal channel={channel}/>
          {:else}
            <Row class="justify-content-center align-items-center">
              <Col md={8} xl={6} xxl={5}>
                  <Card>
                      <CardBody>
                        <div class="text-center">
                          <div class="avatar-md mx-auto">
                              <img src="/assets/images/rocket.svg" alt="rocket launch"/>
                          </div>
                          {#if fatal_error === 'Invalid api key'}
                            <div class="p-2 mt-4">
                                <h4>{$_("channel.permissions_title")}</h4>
                                <p class="mb-4">{$_("channel.permissions_text")}</p>
                            </div>
                            <a href="https://hub.360dialog.com/dashboard/app/BEBD6pPA/permissions" class="btn btn-success btn-lg waves-effect waves-light" target="_blank">
                              {$_("channel.permissions_url")}
                            </a>
                          {:else if fatal_error?.message}
                            <div class="p-2 mt-4">
                                <h4>{$_("channel.support_error.title")}</h4>
                                <p class="mb-4">{@html $_("channel.support_error.text")}</p>
                            </div>
                            <button class="btn btn-secondary btn-md" on:click={updateApiKey}>
                              {#if loading == 'update'}
                                  <i class="bx bx-loader bx-spin font-size-16 align-middle me-2"/>
                              {/if}
                              {$_("channel.support_error.update_the_channel")}
                            </button>
                          {:else}
                            <div class="p-2 mt-4">
                                <h4>{$_("channel.unavailable.title")}</h4>
                                <p class="mb-4">{$_("channel.unavailable.text")}</p>
                            </div>
                            <button class="btn btn-secondary btn-md" on:click={reloadChannel}>
                                {#if loading == 'reload'}
                                    <i class="bx bx-loader bx-spin font-size-16 align-middle me-2"/>
                                {/if}
                                {$_("channel.unavailable.reload")}
                            </button>
                          {/if}
                        </div>
                      </CardBody>
                    </Card>
                </Col>
            </Row>
          {/if}
        {:else}
          <Container fluid>
            <div class="d-md-flex">
              <Col class="me-0 me-md-5 me-xxl-0">
                  <ChannelInfo {channel} user={activeUser} {channelAdditionalInfo} {wabaInfo}/>
                  <Row>
                    <Col xl="6" lg="6">
                      <Card class="channel-mini-card">
                          <CardBody>
                              <div class="d-flex flex-column flex-sm-row align-items-center justify-content-between">
                                  <div class="me-sm-2">
                                      <p class="text-center text-sm-start">{$_("channel.blocks.business.title")}</p>
                                      <h4 class="title bold">{wabaInfo('ACCOUNT_NAME')}</h4>
                                      <!--<Link class="mb-0" to="project/{channel.projectId}">
                                          {#if channel.project && channel.project.name}
                                              <h4 class="title">
                                                  {channel.project.name}
                                              </h4>
                                          {/if}
                                      </Link>-->
                                  </div>
                                  <div class="d-flex align-items-center">
                                      {#if avatar}
                                          <Button color="success" on:click={uploadAvatar} class="btn-sm me-2 btn btn-primary px-2">
                                            {#if saving}
                                              <i class="bx bx-loader bx-spin"/>
                                            {:else}
                                              <i class="mdi mdi-content-save"/>
                                            {/if}
                                          </Button>
                                      {/if}
                                      <div class="avatar-wrap">
                                          <div class="avatar-dropzone dropzone wh-62 align-items-center justify-content-center pointer">
                                              <label class="m-0 d-flex align-items-center justify-content-center pointer">
                                                <i class="font-size-28 text-muted bx bxs-cloud-upload"/>
                                                <input class="d-none" type="file" accept="image/png, image/jpeg" on:change={(e) => file = e.target.files[0]}/>
                                              </label>
                                          </div>
                                          {#if channelAdditionalInfo.photo || avatar}
                                              <img src={avatar || channelAdditionalInfo.photo} alt="avatar" class="rounded-circle border wh-62 object-fit-cover avatar-img"/>
                                          {:else}
                                            <div class="rounded-circle wh-62 avatar-img">
                                              <span class="avatar-title rounded-circle bg-soft bg-primary text-primary font-size-20">
                                                {wabaInfo('ACCOUNT_NAME')?.toUpperCase().charAt(0) ?? ''}
                                              </span>
                                            </div>
                                          {/if}
                                      </div>
                                  </div>
                              </div>
                              <div class="table-responsive mt-4">
                                  <table class="table align-middle table-nowrap mb-0">
                                      <tbody>
                                      <tr>
                                          <td>
                                            <span class="text-body">{$_("channel.blocks.info.waba_account_status")}</span>
                                          </td>
                                          <td class="text-sm-end">
                                            <span class="badge badge-pill badge-soft-{wabaInfo('WABA_ACCOUNT_STATUS', 'color')} font-size-11">
                                                {wabaInfo('WABA_ACCOUNT_STATUS')}
                                            </span>
                                          </td>
                                      </tr>
                                      <!-- <tr>
                                          <td>
                                            <span class="text-body">{$_("channel.blocks.info.message_on_behalf")}</span>
                                          </td>
                                          <td class="text-sm-end">
                                            <span class="badge badge-pill badge-soft-{wabaInfo('MESSAGE_ON_BEHALF', 'color')} font-size-11">
                                                {wabaInfo('MESSAGE_ON_BEHALF')}
                                            </span>
                                          </td>
                                        </tr>-->
                                      <tr>
                                          <td>
                                              <span class="text-body">{$_("channel.blocks.info.fb_business_verification")}</span>
                                          </td>
                                          <td class="text-sm-end">
                                            <span class="badge badge-pill badge-soft-{wabaInfo('FB_BUSINESS_VERIFICATION', 'color')} font-size-11">
                                                {wabaInfo('FB_BUSINESS_VERIFICATION')}
                                            </span>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <span class="text-body">{$_("channel.blocks.info.current_limit")}</span>
                                          </td>
                                          <td class="text-sm-end">
                                            <span class="badge badge-pill badge-soft-{wabaInfo('CURRENT_LIMIT', 'color')} font-size-11">
                                                {@html channel.waba_info?.current_limit && $_('waba.limits.' + channel.waba_info.current_limit) || 'N/A'}
                                            </span>
                                          </td>
                                      </tr>
                                      </tbody>
                                  </table>
                              </div>
                          </CardBody>
                      </Card>
                    </Col>
                    <Col xl="6" lg="6">
                      <Card class="channel-mini-card">
                          <CardBody>
                              <div class="d-flex flex-column flex-sm-row align-items-center justify-content-between">
                                  <div class="me-sm-2">
                                      <p class="text-center text-sm-start">{$_("channel.blocks.phone.title")}</p>
                                      <h4 class="title bold">{channelAdditionalInfo.phone ? "+" + channelAdditionalInfo.phone : "-"}</h4>
                                  </div>
                                  <div class="phone-icon wh-62 d-flex align-items-center justify-content-center rounded-circle">
                                      <i class="bx bx-phone"/>
                                  </div>
                              </div>
                              <div class="table-responsive mt-4">
                                  <table style="scrollbar-width:thin" class="table align-middle table-nowrap mb-0">
                                      <tbody>
                                      <tr>
                                          <td>
                                              <span class="text-body">{$_("channel.blocks.info.external_waba_channel")}</span>
                                          </td>
                                          <td class="text-sm-end">
                                            <span class="badge badge-pill badge-soft-{wabaInfo('EXTERNAL_WABA_CHANNEL', 'color')} font-size-11">
                                                {wabaInfo('EXTERNAL_WABA_CHANNEL')}
                                            </span>
                                          </td>
                                      </tr>
                                      <tr>
                                          <td>
                                              <span class="text-body">{$_("channel.blocks.info.display_name_verification")}</span>
                                          </td>
                                          <td class="text-sm-end">
                                            <span class="badge badge-pill badge-soft-{wabaInfo('DISPLAY_NAME_VERIFICATION', 'color')} font-size-11">
                                                {wabaInfo('DISPLAY_NAME_VERIFICATION')}
                                            </span>
                                          </td>
                                      </tr>
                                      <tr>
                                          <td>
                                              <span class="text-body">{$_("channel.blocks.info.number_registration")}</span>
                                          </td>
                                          <td class="text-sm-end">
                                            <span class="badge badge-pill badge-soft-{wabaInfo('NUMBER_REGISTRATION', 'color')} font-size-11">
                                              {wabaInfo('NUMBER_REGISTRATION')}
                                            </span>
                                          </td>
                                      </tr>
                                      </tbody>
                                  </table>
                              </div>
                          </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  {#each staticAlerts as alert}
                    <div class:d-none={alert.hide} role="alert" class="alert-dismissible fade show alert alert-warning">
                      <button type="button" class="btn-close" aria-label="Close" on:click={() => alert.hide = true}/>
                      <h4 class="alert-heading"><i class="mdi mdi-alert-outline me-2"/>{alert[lang].title}</h4>
                      <p>{alert[lang].text}</p>
                    </div>
                  {/each}
                  {#if !channel.stopped}
                    <Card>
                      <CardBody>
                        {#if activeTab}
                          <TabContent pills class="custom-tab" on:tab={(e) => activeTab = e.detail} {activeTab}>
                            {#if statistic.length}
                              <TabPane tabId={2} class="pt-3" tab={$_('waba.statistic')} active={activeTab == 2}>
                                <ChannelStatistic statistics={statistic} {channel}/>
                              </TabPane>
                              <TabPane tabId={1} class="pt-3" tab={$_('waba.settings')} active={activeTab == 1}>
                                <ChannelSettings {channel} {channelAdditionalInfo} {updateChannelAdditionalInfo}/>
                              </TabPane>
                            {:else}
                              <TabPane tabId={1} class="pt-3" tab={$_('waba.settings')} active={activeTab == 1}>
                                <ChannelSettings {channel} {channelAdditionalInfo} {updateChannelAdditionalInfo}/>
                              </TabPane>
                              <TabPane tabId={2} class="pt-3" tab={$_('waba.statistic')} active={activeTab == 2}>
                                <ChannelStatistic statistics={statistic} {channel}/>
                              </TabPane>
                            {/if}
                            <TabPane tabId={3} class="pt-3" tab={$_('waba.templates')} active={activeTab == 3}>
                              <Templates {channel} {channelAdditionalInfo}/>
                            </TabPane>
                          </TabContent>
                        {:else}
                          <div class="text-center">
                            <Spinner/>
                          </div>
                        {/if}
                      </CardBody>
                    </Card>
                  {:else}
                    <StoppedChannelModal channel={channel} type="alert"/>
                  {/if}
                  <!-- <Row>
                    <Col md="4">
                      <Card class="mini-stats-wid">
                        <CardBody>
                          <div class="d-flex">
                            <div class="flex-grow-1">
                              <p class="text-muted fw-medium">
                                {$_("channel.blocks.subscription.title")}
                              </p>
                              {#if channel.subscription}
                                <h4 class="mb-5">
                                  {$_("channel.blocks.subscription.active")}
                                </h4>
                                <p class="text-muted fw-medium">
                                  {$_("channel.blocks.subscription.managment")}
                                </p>
                              {:else}
                                <h4 class="mb-0">
                                  {$_("channel.blocks.subscription.not_active")}
                                </h4>
                              {/if}
                            </div>
                            <div class="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                              <span class="avatar-title">
                                <i class="bx bx-credit-card font-size-24" />
                              </span>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row> -->
              </Col>
              <div class="emulator-background {emulatorModalState ? 'active' : ''}" on:click={() => emulatorModalState = !emulatorModalState}/>
              <div class="emulator {emulatorModalState ? 'active': ''}">
                  <div class="emulator__btn" on:click={() => emulatorModalState = !emulatorModalState}>
                      <i class="bx bx-mobile-alt"/>
                      <div class="font-size-14">{$_("channel.emulator.button")}</div>
                  </div>
                  <div class="h-100 overflow-auto">
                      <button type="button" class="btn-close" aria-label="Close" on:click={() => emulatorModalState = !emulatorModalState}/>
                      <div class="emulator-phone">
                          <span/>
                          <div class="emulator-phone__container">
                              <!-- {#if !!activeUser?.appToken}
                                  <iframe
                                      src={clientLocal.getEmulatorUrl(channel)}
                                      allow="camera *;microphone *;geolocation *"
                                      loading="lazy"
                                      title="emulator 1msg"
                                      frameborder="0"
                                      class="emulator-phone__iframe">
                                      Your browser does not support frames!
                                  </iframe>
                              {:else}
                                  <div class="h-100 position-relative">
                                      <img src="/assets/images/mockup-iphone-plug.png" alt="mockup-iphone-plug"/>
                                      <div class="emulator-phone__btnPlug">
                                          <button type="button"
                                                  on:click={registerEmulatorToken}>{$_("channel.emulator.start")}</button>
                                      </div>
                                  </div>
                              {/if} -->
                            <div class="h-100 position-relative bg-white d-flex align-items-center">
                              <div class="text-center w-100 p-3 mb-5">
                                <img src="/assets/images/logo-1msg-dark-mini.svg" alt="logo 1msg" class="emulator-phone__logo"/>
                                <div class="fw-600 text-dark mb-3 emulator-phone__title">1msg</div>
                                <div class="emulator-phone__btnPlug mb-5">
                                  <button type="button" on:click={() => navigate("/inbox")}>
                                    {$_("channel.emulator.redirect_inbox")}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <img src="/assets/images/mockup-iphone.png" alt="mockup-iphone" class="emulator-phone__wrapper"/>
                      </div>
                  </div>
              </div>
            </div>
          </Container>
        {/if}
    {:else if channel}
        <Container lg class="px-0">
            <ChannelInit {channel} user={activeUser}/>
        </Container>
    {/if}
</div>

<style>
  .wh-62 {
    width: 62px;
    height: 62px;
  }

  .dropzone {
    min-height: auto;
  }

  .avatar-wrap:hover .avatar-img {
    display: none;
  }

  .avatar-wrap:hover .avatar-dropzone {
    display: flex;
  }

  .avatar-wrap .avatar-dropzone {
    display: none;
    transition: all 0.3s;
  }

  .emulator-phone .emulator-phone__logo {
    width: 75px;
  }

  .emulator-phone .emulator-phone__title {
    font-size: 45px;
  }
</style>
