<script>

  import { Row, Col, Card, CardBody, CardTitle } from "sveltestrap";
  import { _, date } from "svelte-i18n";
  import StatististicsChart from './StatististicsChart.svelte';
  import { getRequest } from "../../helpers/utils";

  export let channel;
  export let statistics;

  const props = [
    'business_initiated_paid_quantity',
    'business_initiated_price',
    'business_initiated_quantity',
    'free_entry_point',
    'free_quantity',
    'free_tier',
    'paid_quantity',
    'quantity',
    'total_price',
    'user_initiated_paid_quantity',
    'user_initiated_price',
    'user_initiated_quantity',
    "marketing_quantity",
    "marketing_price",
    "service_quantity",
    "service_price",
    "utility_quantity",
    "utility_price"
  ]

  let statusesToShow,
    modal = false;
  const tog_modal = () => {
    modal = !modal;
  };

  const init = async () => {
    if(!statistics){
      if (channel.apiUrl && channel.waba_info) {
        let result = await getRequest(`${channel.apiUrl}statistics2`, {
          token: channel.token,
        });
        if (result) {
          statistics = result;
          if(statistics.length){
            statistics.reverse();
          }
        }
      }
    }

  };


  $: if (channel) {
    init();
  }

  const json = obj => JSON.stringify(obj, null, 2);
</script>


<Row>
  {#if statistics && statistics.length}
    <StatististicsChart data={statistics} />
    {#each statistics as item}
      <h3>{$date(new Date(item.period_date), { format: "short" })}</h3>
      {#each props as prop}
        <Col lg={3}>
          <Card class="mini-stats-wid">
            <CardBody>
              <div class="d-flex flex-wrap">
                <div class="me-3">
                  <p class="text-muted mb-2">{$_("channel.blocks.statistic." + prop)}</p>
                  <h5 class="mb-0">
                    {#if prop.indexOf('price') > -1}
                      ${(+item[prop].toFixed(2))}
                    {:else}
                      {item[prop]}
                    {/if}
                  </h5>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      {/each}
    {/each}
  {:else}
    <div role="alert" class="alert alert-info "
         style="">
      <i class="mdi mdi-alert-circle-outline me-2"></i>
      {$_("channel.blocks.statistic.no_items")}
    </div>
  {/if}
</Row>

