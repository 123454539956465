<script>
    import {
        Modal, ModalHeader, ModalBody,
        Row,
        Col
    } from "sveltestrap";
    import {onMount, onDestroy} from "svelte";
    import {paymentProfiles, channels} from "../../helpers/store";
    import {defaultConst} from "../../common/constants";
    import {getRates} from "../../helpers/getRates";
    import {_} from "svelte-i18n";
    import {postRequest} from "../../helpers/utils";
    import Spinner from "../Spinners/SpinnerComponent.svelte";
    import {addToast} from "../../helpers/toast";

    export let show;
    export let toggleModal;
    export let channel;

    let paymentProfilesLocal,
        paymentProfile = {},
        activeUser,
        lang = 'en',
        loading = false,
        subscriptionTranslations,
        pricings = {},
        activeRate = false;


    async function updatePricing() {
        if (channel) {
            pricings = await getRates(channel.project, channel)
        }
    }

    async function updateProfile() {
        if (channel && channel.paymentProfileId) {
            paymentProfile = paymentProfilesLocal.find((item) => {
                return item.id === channel.paymentProfileId
            });
            if (paymentProfile) {
                if (paymentProfile.rate === '1 year') {
                    subscriptionTranslations = {
                        title: 'subscription.annual',
                        description: 'subscription.annual_description',
                        duration: 'subscription.per_year'
                    }
                } else {
                    subscriptionTranslations = {
                        title: 'subscription.monthly',
                        description: 'subscription.monthly_description',
                        duration: 'subscription.per_month'
                    }
                }
            }
            lang = paymentProfile.currency?.toLowerCase() === 'rub' && 'ru' || 'en';
        }
        updatePricing();
        if(!activeRate) activeRate = {rate: paymentProfile.rate, provider: paymentProfile.paymentSystem};
    }


    const unsub = paymentProfiles.subscribe(async (value) => {
        paymentProfilesLocal = value;
        paymentProfile = {};
        await updateProfile();


    });
    const unsub2 = channels.subscribe(async (value) => {
        let channelTmp = value.find((item) => {
            return item.id === channel.id
        });
        if (channelTmp !== channel) {
            channel = channelTmp;
            await updateProfile();
        }
    });


    function changeRate(data) {
        activeRate = {
            provider: data.payment.id,
            rate: data.price.rate
        };
    }

    async function changeSubscriptionRate() {
        if (loading) return;
        if (!paymentProfile.paymentSystem) {
            return addToast({
                title: $_("error"),
                message: $_("something_went_wrong")
            });
        }
        loading = 'card';
        let params = {id: channel.id, profile: paymentProfile.id, rate: activeRate.rate};

        let result = await postRequest(
            `${defaultConst.paymentUrl}/change-subscription`,
            params
        );

        loading = false;
        if (!result || result.error) {
            addToast({
                title: $_("error"),
                message: result.error || $_("something_went_wrong")
            });
        } else {
            addToast({title: $_("saved")});
            toggleModal();
            show = false;
        }


    }

    onDestroy(() => {
        unsub();
        unsub2();
    });


</script>

<Modal isOpen={show}
       fade={true}
       size="md"
       class="modal-add customize-subscription"
       scrollable
       centered
>

    <ModalHeader on:click={toggleModal}>

        <span class="text-primary">{$_('customize.title')}</span>
        <button type="button"
                class="close"
                receipts-dismiss="modal"
                aria-label="Close"
                on:click={toggleModal}
        />
    </ModalHeader>

    <ModalBody>
        {#if loading}
            <div class="text-center">
                <Spinner></Spinner>
            </div>
        {:else}
            {#if (paymentProfile && paymentProfile.active)}
                <div>
                    <div class="bold text-center font-size-16">{@html $_('customize.text')}</div>
                    <div class="mt-4 ">
                        {#if (pricings && pricings[lang])}
                            {#each pricings[lang] as payment}
                                {#each payment.prices as pricing,index}
                                    <div class="mb-3 d-flex align-items-center justify-content-between form-check payment-check  {pricing.payment.id == activeRate?.provider && pricing.price.rate == activeRate?.rate && 'active' || ''}"
                                         on:click={() => {
                                                   changeRate(pricing)
                                                }}>
                                        <div>
                                            <sup style="left: 0.25em;"> {$_(pricing.price.currency)}</sup>
                                            {pricing.price.value}/<span
                                                class="font-size-16">{$_(pricing.duration)}</span>
                                        </div>
                                        <div class="current-logo text-end col-4">
                                            <img class="img-fluid"
                                                 src={pricing.payment.logo}
                                                 alt="logo">
                                        </div>

                                    </div>
                                {/each}
                            {/each}
                        {/if}
                        <div class="mt-5 d-flex justify-content-center">
                            <button disabled={!activeRate || activeRate.rate === paymentProfile.rate}
                                    on:click={() => changeSubscriptionRate()}
                                    class="btn btn-royal-blue"
                            >{$_('customize.btn')}</button>
                        </div>

                    </div>
                </div>
            {:else}
                <div class="mt-5 bold text-center font-size-16">{@html $_('customize.no_subscription')}</div>
            {/if}

        {/if}
    </ModalBody>

</Modal>
