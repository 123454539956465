import {connect as connectNats, jwtAuthenticator, credsAuthenticator, JSONCodec, StringCodec} from "nats.ws";
import {defaultConst} from "../../common/constants";
import {writable} from "svelte/store";

const natsClient = new NatsLocalClass();
export default natsClient;

function NatsLocalClass() {
  this.socket = null;
  this.watching = false;
  this.watchingStore = writable(false);
  this.terminateFunction = null;

  //ключи содержат соответственные ключи которые генерятся объектами подписки. Сами объекты следят за удалением необходимых
  // данных из этой очереди.
  this.subscriptionObject = {};
  this.dataHandlersObject = {};
  const defaultOnConnectionChange = (isConnected, natsConnection = false) => {
    this.watchingStore.set(isConnected)
  }

  this.onConnectionChange = defaultOnConnectionChange

  const addHandler = ({id, handleObject}) => {
    this.dataHandlersObject[id] = handleObject
  }

  const deleteHandler = (id) => {
    delete this.dataHandlersObject[id]
  }


  const changeSettingsAndConnect = async (subscriptionObject = {}, dataHandlersObject = null, onConnectionChange = () => {
  }) => {
    // if (!isEqual(this.subscriptionObject, subscriptionObject) || !isEqual(dataHandlersObject, this.dataHandlersObject)) {
    await terminate();
    this.subscriptionObject = subscriptionObject;
    if (dataHandlersObject !== null)
      this.dataHandlersObject = dataHandlersObject;
    this.onConnectionChange = (isConnected, natsConnection = false) => {
      onConnectionChange(isConnected, natsConnection)
      defaultOnConnectionChange(isConnected, natsConnection)
    };
    return reconnectToNats().then(data => data, err => {
    });
    // }

  }

  const terminate = async () => {
    this.subscriptionObject = {};
    this.dataHandlersObject = {};
    this.onConnectionChange = (isConnected, natsConnection = false) => {
    };
    typeof this.terminateFunction?.terminateFunctionLocal === 'function' && this.terminateFunction.terminateFunctionLocal();
    this.socket && await this.socket.close();
    return;
  }

  const connectToNats = async (ensureConnection = false) => {

    const natsServer = import.meta.env.VITE_REACT_APP_NATS_SERVER ?? defaultConst.natsUrl;
    const natsPort = import.meta.env.VITE_REACT_APP_NATS_PORT ?? '4443';
    const natsProtocol = import.meta.env.VITE_REACT_APP_NATS_PROTOCOL ?? 'wss';

    let options = {
      servers: `${natsProtocol}://${natsServer}:${natsPort}`,
      // token: natsToken,
      authenticator: jwtAuthenticator(this.subscriptionObject.nats_jwt, new TextEncoder().encode(this.subscriptionObject.nats_seed)),
      // authenticator: credsAuthenticator(new TextEncoder().encode(creds)),
      pingInterval: 10000, // не трогать, если убрать в переменную - случается черная магия и ничего не работает
      timeout: 3000,
      maxReconnectAttempts: 1,
      maxPingOut: 3,
    };
    // console.log(options)

    return connectNats(options).then(nc => {
      this.socket = nc;
      // console.log(`connected to ${nc.getServer()}`);
      // console.log(`connect123ed to ${nc.getServer()}`);
      if (!ensureConnection) {
        this.onConnectionChange(true, nc);
      }

      const sc = JSONCodec();

      const sub = nc.subscribe(`${this.subscriptionObject.shop_id}.>`);

      watchForDissconections(nc);
      (async () => {
        for await (const m of sub) {
          // const strrr = StringCodec();
          // console.log(`[${m.subject}]: ${strrr.decode(m.data)}`);
          if (ensureConnection) {
            this.onConnectionChange(true, nc);
          }
          for (let dataHandlersObjectKey in this.dataHandlersObject) {
            const subject = m.subject.toString();
            let message = sc.decode(m.data);
            if(this.dataHandlersObject[dataHandlersObjectKey]?.prepareNatsData){
              this.dataHandlersObject[dataHandlersObjectKey]?.prepareNatsData(subject,message)
            }else{
              delete this.dataHandlersObject[dataHandlersObjectKey]
            }

          }
        }

      })();

      return nc;
    }, err => {
      this.onConnectionChange(false);
      return false;

    });
  }

  const delay = (t, val) => {
    return new Promise((res) => {
      setTimeout(() => {
        res(val ? val : true);
      }, t);
    });
  }

  const reconnectToNats = async (ensureConnection = false) => {

    const timerTimeout = 15000;
    let ignore = false;
    const terminateFunctionLocal = () => {
      ignore = true;
    }

    this.terminateFunction = {terminateFunctionLocal: terminateFunctionLocal};
    return new Promise(async (res, rej) => {
      let flag = false;

      while (flag === false && !ignore) {
        try {

          if (Object.keys(this.subscriptionObject).length === 0 || ignore) {
            rej(false);
            break;
          }
          const nats = await connectToNats(ensureConnection);
          if (nats) {
            flag = true;
            this.terminateFunction = null;
            res(nats);
            break;
          } else {
            await delay(timerTimeout);
          }
        } catch (e) {
          await delay(timerTimeout);
        }

      }
    })

  }

  const getWatchingStore = () => {
    return this.watchingStore
  }

  const watchForDissconections = (natsConnection) => {
    if (!this.watching) {
      this.watching = true;
      (async () => {
        if (natsConnection) {
          for await (const s of natsConnection.status()) {
            if (s.type === 'staleConnection') {
              this.onConnectionChange(false);
              natsConnection.close();
            }
          }
        } else {
          console.info(`socket undefined`);
        }
      })().then();
      natsConnection.closed().then(err => {
        this.onConnectionChange(false);
        console.error(err);
        reconnectToNats(true).then(data => data, err => {
        });
      })
    }
    return;
  }

  return {reconnectToNats, terminate, changeSettingsAndConnect, addHandler, deleteHandler, getWatchingStore}
}
