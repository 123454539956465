<script>
  import {Col, Container, Row, Card, CardHeader, CardBody, Button, Input, FormGroup, Label, Spinner} from "sveltestrap";
  import {_} from "svelte-i18n";
  import {toolbar} from "../../services/loaders";
  import {addToast} from "../../helpers/toast";
  import {user as fbUser} from "../../helpers/firebase";
  import {defaultConst} from "../../common/constants";
  import {user, appClient} from "../../helpers/store";
  import {postRequest} from "../../helpers/utils";
  import {onDestroy, onMount} from "svelte";
  import {copyText} from "../../helpers/utils";
  import SpinnerComponent from "../../Components/Spinners/SpinnerComponent.svelte";

  let activeUser = {};
  let loading = false;
  let saving = false;
  let newPassword = '';
  let changePasswordConfirm = '';
  let passwordError = false;
  let changePasswordAvailable =  fbUser?.providerData?.[0]?.providerId === 'password' || false;
  let inputActive = {};

  const unsub = user.subscribe((value) => {
    activeUser = value;
  });

  let activeOperatorLoader = true;
  let activeOperator = null;
  let operatorAvatar = "";
  let operatorName = "";
  let operatorEmail = "";

  const getMeProfile = () => {
    return appClient.getMeProfile().then(data => {
      if (data?.status !== 'ok') {
        addToast({
          title: $_("error"),
          message: $_("something_went_wrong"),
          dismissible: true,
          timeout: 4_000,
        });
        console.error(data);
        return;
      }
      activeOperator = data.result;
      operatorAvatar = activeOperator.avatar_url;
      operatorName = activeOperator.user.username;
      operatorEmail = activeOperator.user.email;
    }).catch(err => {
      addToast({
        title: $_("error"),
        message: $_("something_went_wrong"),
        dismissible: true,
        timeout: 4_000,
      });
      console.error(err);
    })
  };

  onMount(() => {
    getMeProfile().finally(()=>activeOperatorLoader=false);
  });

  onDestroy(() => {
    unsub();
  });

  const startEdit = (name) => {
    inputActive = {
      name: name || "",
      value: activeUser[name] || "",
    };
  };

  const cancelEdit = () => {
    inputActive = {};
  };

  const changeProp = async () => {
    if (saving) return;
    saving = true;
    toolbar(saving)
    const params = {
      userToken: activeUser.userToken,
      property: inputActive.name,
    };
    if (inputActive.value) params.value = inputActive.value;

    let result;

    try {
      result = await postRequest(
        `${defaultConst.functionsUrl}/updateUser`,
        params
      );
    } catch (e) {
    }
    saving = false;
    toolbar(saving)
    if (!result || result.error) {
      addToast({
        title: $_("error"),
        message: result.error || $_("something_went_wrong"),
        dismissible: true,
        timeout: 4_000,
      });
    } else {
      addToast({title: $_("saved")});
      cancelEdit();
    }
  };

  const copyToClipoard = (text) => {
    copyText(text);
    addToast({
      title: "copied",
      message: text,
      dismissible: true,
      timeout: 2_000,
    });
  };

  const createNewUserToken = async () => {
    if (loading) return;
    loading = true;
    const params = {
      userToken: activeUser.userToken,
      uid: activeUser.id,
    };

    let result = await postRequest(
      `${defaultConst.functionsUrl}/changeUserToken`,
      params
    );

    if (!result || result.error) {
      addToast({
        title: $_("error"),
        message: result.error || $_("something_went_wrong"),
        dismissible: true,
        timeout: 4_000,
      });
    } else {
      addToast({title: $_("saved")});
      activeUser.userToken = result.token;
    }
    loading = false;
  };

  const changePassword = async () => {
    if (loading || passwordError) return;
    if (fbUser) {
      console.dir(fbUser)
      loading = 'password';
      try{
        let result = await fbUser.updatePassword(newPassword)
        addToast({title: $_("saved")});
      }catch (e){
        console.error(e)
        addToast({
          title: $_("error"),
          message: e.message || $_("something_went_wrong"),
          dismissible: true,
          timeout: 4_000,
        });
      }
      loading = false;
    }
  };

  const onPasswordChange = () => {
    if(changePasswordConfirm !== newPassword){
      passwordError = "profile.error.password_match";
      return;
    }
    if(newPassword.length < 6){
      passwordError = "profile.error.password_short";
      return;
    }
    passwordError = false;
  };

  let loadingUpdatePersonalInfo = false;
  let file = null;
  let newAvatar = "";
  $: if (file !== null) {
    if (!file.type.match('image.*')) {
        addToast({title: $_("profile.error.pleas_image")});
    } else {
      const reader = new FileReader();
      reader.onload = (function() {
        return function(e) {
          newAvatar = e.target.result;
        }
      })(file);
      reader.readAsDataURL(file);
    }
  }

  const updatePersonalInfo = async () => {
    loadingUpdatePersonalInfo = true;
    try {
      operatorName = operatorName.replace(/\s+/g, ' ').trim();
      let username = operatorName !== activeOperator.user.username ? operatorName : undefined;
      const res = await appClient.postUpdateOperatorInformation(username, operatorEmail, file);

      if (!res || res.error) {
        addToast({
          title: $_("error"),
          message: res.error || $_("something_went_wrong")
        });
      } else {
        if (res.status === 'ok') {
          addToast({title: $_("saved")});
        } else if (res.username) {
          addToast({title: $_("profile.this_name_is_already_taken")});
        }

        await getMeProfile();
        file = null;
        newAvatar = "";
      }
      loadingUpdatePersonalInfo = false;
    } catch (e) {
      console.error(e)
      addToast({
        title: $_("error"),
        message: $_("something_went_wrong"),
        dismissible: true,
        timeout: 4_000,
      });
      loadingUpdatePersonalInfo = false;
    }
  };
</script>

<div class="page-content">
{#if activeOperatorLoader}
  <div class="d-flex align-items-center justify-content-center p-3">
    <SpinnerComponent/>
  </div>
{:else}
  <Container fluid>
    <div class="header-title">{$_("profile.user_details")}</div>
    <Row>
      <Col xl="7">
        {#if activeOperator}
          <Card>
            <CardHeader class="font-size-16 fw-600 border-bottom bg-white px-4 py-3">
              {$_("profile.personal_info")}
            </CardHeader>
            <CardBody class="py-3 d-xl-flex">
              <div class="d-flex justify-content-center">
                <div class="me-xl-4 mb-3 d-inline-block">
                  <div class="position-relative avatar-wrapper">
                    {#if newAvatar || operatorAvatar}
                      <img src="{newAvatar ? newAvatar : operatorAvatar}" class="avatar-lg rounded-circle object-fit-cover" alt="avatar"/>
                    {:else}
                      <div class="avatar-lg d-inline-block">
                        <span class="avatar-title rounded-circle bg-soft bg-primary text-primary font-size-16">
                          {operatorEmail.toUpperCase().charAt(0)}
                        </span>
                      </div>
                    {/if}
                    <label class="position-absolute translate-middle badge pointer rounded-pill bg-success d-flex align-items-center justify-content-center">
                      <input
                        class="d-none"
                        type="file"
                        accept="image/png, image/jpeg"
                        on:change={(e) => file = e.target.files[0]}/>
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M11.3182 4.59516C11.3422 4.63712 11.3842 4.66708 11.4382 4.66708C12.8782 4.66708 14.0542 5.84178 14.0542 7.28018V10.8402C14.0542 12.2786 12.8782 13.4533 11.4382 13.4533H4.6702C3.2242 13.4533 2.0542 12.2786 2.0542 10.8402V7.28018C2.0542 5.84178 3.2242 4.66708 4.6702 4.66708C4.7182 4.66708 4.7662 4.64311 4.7842 4.59516L4.8202 4.52324C4.84088 4.47971 4.86211 4.43499 4.88367 4.38959C5.03716 4.06628 5.20697 3.7086 5.3122 3.49838C5.5882 2.95898 6.0562 2.65931 6.6382 2.65332H9.4642C10.0462 2.65931 10.5202 2.95898 10.7962 3.49838C10.8907 3.68719 11.0347 3.99132 11.1735 4.28445C11.2021 4.34491 11.2305 4.4049 11.2582 4.46331L11.3182 4.59516ZM10.8802 6.89661C10.8802 7.19627 11.1202 7.43601 11.4202 7.43601C11.7202 7.43601 11.9662 7.19627 11.9662 6.89661C11.9662 6.59694 11.7202 6.35121 11.4202 6.35121C11.1202 6.35121 10.8802 6.59694 10.8802 6.89661ZM7.0162 7.82557C7.2982 7.54389 7.6642 7.39405 8.0542 7.39405C8.4442 7.39405 8.8102 7.54389 9.0862 7.81958C9.3622 8.09527 9.5122 8.46087 9.5122 8.85043C9.5062 9.65354 8.8582 10.3068 8.0542 10.3068C7.6642 10.3068 7.2982 10.157 7.0222 9.88129C6.7462 9.6056 6.5962 9.24 6.5962 8.85043V8.84444C6.5902 8.46686 6.7402 8.10127 7.0162 7.82557ZM9.7162 10.5166C9.2902 10.9421 8.7022 11.2058 8.0542 11.2058C7.4242 11.2058 6.8362 10.9601 6.3862 10.5166C5.9422 10.0671 5.6962 9.47974 5.6962 8.85043C5.6902 8.22713 5.9362 7.63978 6.3802 7.19028C6.8302 6.74078 7.4242 6.49505 8.0542 6.49505C8.6842 6.49505 9.2782 6.74078 9.7222 7.18429C10.1662 7.63379 10.4122 8.22713 10.4122 8.85043C10.4062 9.50371 10.1422 10.0911 9.7162 10.5166Z"
                          fill="white"/>
                      </svg>
                    </label>
                  </div>
                </div>
              </div>
              <div class="w-100">
                <form on:submit|preventDefault={updatePersonalInfo}>
                  <Row>
                    <Col md={6}>
                      <FormGroup class="mb-3">
                        <Label>{$_("profile.name")}</Label>
                        <Input type="text" bind:value={operatorName} required class="font-size-13"/>
                      </FormGroup>
                    </Col>
                    <!-- <Col md={6}>
                      <FormGroup class="mb-3">
                        <Label>{$_("profile.language")}</Label>
                        <select class="form-control form-select font-size-13">
                          <option value="">Select</option>
                        </select>
                      </FormGroup>
                    </Col> -->
                    <Col md={6}>
                      <FormGroup class="mb-3">
                        <Label>{$_("profile.email")}</Label>
                        <Input type="email" bind:value={operatorEmail} disabled class="font-size-13"/>
                      </FormGroup>
                    </Col>
                    <!-- <Col md={6}>
                      <FormGroup class="mb-3">
                        <Label>{$_("profile.timezone")}</Label>
                        <select class="form-control form-select font-size-13">
                          <option value="">GMT+03:00</option>
                        </select>
                      </FormGroup>
                    </Col> -->
                  </Row>
                  <Button type="submit" color="success" class="font-size-14 fw-500 px-3" disabled={loadingUpdatePersonalInfo}>
                    {#if loadingUpdatePersonalInfo}
                      <Spinner size="sm"/>
                    {/if}
                    {$_("profile.save_details")}
                  </Button>
                </form>
              </div>
            </CardBody>
          </Card>
        {/if}
        {#if changePasswordAvailable}
          <Card>
            <CardHeader class="font-size-16 fw-600 border-bottom bg-white px-4 py-3">
              {$_("profile.settings")}
            </CardHeader>
            <CardBody class="py-3">
              <form on:submit|preventDefault={changePassword}>
                <Row>
                  <Col md={6}>
                    <FormGroup class="mb-3">
                      <Label for="formrow-email-Input">{$_("profile.password")}</Label>
                      <Input
                        bind:value={newPassword}
                        on:change={() => {onPasswordChange()}}
                        type="password"
                        class="form-control"
                        id="formrow-email-Input"
                        placeholder={$_("profile.password")}
                      />
                      {#if (passwordError)}
                        <div class="text-danger">{$_(passwordError)}</div>
                      {/if}
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup class="mb-3">
                      <Label for="formrow-password-Input">{$_("profile.password_confirm")}</Label>
                      <Input
                        bind:value={changePasswordConfirm}
                        on:change={() => {onPasswordChange()}}
                        type="password"
                        class="form-control"
                        id="formrow-password-Input"
                        placeholder={$_("profile.password_confirm")}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Button type="submit" color="success" disabled={passwordError}
                  class="font-size-14 fw-500 px-3 {loading ? 'disabled' : ''}">
                  {$_("save")}
                </Button>
              </form>
            </CardBody>
          </Card>
        {/if}
      </Col>
      <Col xl="5">
        {#if activeUser.isOperator}
          <div class="card bg-operator">
            <CardBody class="d-flex align-items-center">
              <div class="pe-3">
                <div class="subtitle font-size-16 fw-600 text-success mb-2">
                  {$_("profile.card_operator.title")}
                </div>
                <div>
                  {$_("profile.card_operator.text")}
                </div>
              </div>
              <img src="/assets/images/about_operator.svg" alt="about_operator" class="d-none d-md-block">
            </CardBody>
          </div>
        {:else}
          <Card>
            <CardHeader class="font-size-16 fw-600 border-bottom bg-white px-4 py-3">
              {$_("profile.api_info")}
            </CardHeader>
            <CardBody class="py-3">
              <Label>{$_("profile.user_token")}</Label>
              <div class="form-control h-auto py-2 mb-3">
                <span class="text-muted font-size-13">{activeUser.userToken}</span>
                <button type="button" class="border-0 p-0 bg-transparent" on:click={() => copyToClipoard(activeUser.userToken)}>
                  <i class="bx bx-copy-alt font-size-20"/>
                </button>
              </div>
              <div class="font-size-13 mb-3">
                {$_("profile.about_token1")}<br/>{$_("profile.about_token2")}
              </div>
              <Button outline color="success" class="font-size-14 fw-500 px-3 {loading ? 'disabled' : ''}"
                on:click={createNewUserToken}>
                {$_("profile.generateNewToken")}
              </Button>
            </CardBody>
          </Card>
        {/if}
      </Col>
    </Row>
  </Container>
{/if}
</div>

<style>
  .avatar-wrapper .badge {
    bottom: -20px;
    right: -15px;
    width: 30px;
    height: 30px;
  }

  /* .form-select {
    box-shadow: none;
  } */

  .bx.bx-copy-alt {
    color: #ADB5BD;
    vertical-align: text-bottom;
    line-height: 16px;
  }

  .bg-operator {
    background-color: #f2f7c7;
  }
</style>
