import {callbacks, db} from './firebase';
import {writable, get, derived} from 'svelte/store';
import {getRequest, postRequest} from "./utils";
import AppClient from "./onetouch/AppClient";
import {defaultConst} from "../common/constants";
import * as amplitude from '@amplitude/analytics-browser';
import {addToast} from "./toast";
import {_} from "svelte-i18n";
import {navigate} from "svelte-routing";

let timeout1;
const projects = writable();
const channelsAdditionalInfo = writable({});
/**
 * Represents a configuration object with various properties.
 * @typedef {Object} ChannelFirestore
 * @property {string} id - The unique identifier for this configuration.
 * @property {string} waba_client_id - The WABA (WhatsApp Business API) client ID.
 * @property {string} waba_channel_id - The WABA channel ID.
 * @property {boolean} noAutoCharge - Indicates if auto charging is disabled.
 * @property {boolean} stopped - Indicates if the configuration is stopped.
 * @property {number} activeTill - The timestamp for when the configuration is active.
 * @property {number} crm_id - The CRM (Customer Relationship Management) ID.
 * @property {string} ownerId - The owner's unique ID.
 * @property {number} srv - The server number.
 * @property {number} paymentProfileId - paymentProfileId
 * @property {string} projectId - The project ID.
 * @property {string} apiUrl - The API URL associated with this configuration.
 * @property {string} token - The access token for this configuration.
 * @property {string} name - The name of the configuration.
 * @property {Object} waba_info - Information related to the WABA configuration.
 * @property {string} phone - The phone number associated with the configuration.
 * @property {Object} appData - Additional application data.
 * @property {Array<{ts: number, val: boolean}>} noAutoChargeLog - Log of noAutoCharge changes.
 * @property {boolean} tracked - Indicates if the configuration is tracked.
 * @property {number} nextReload - The timestamp for the next reload.
 */
/**
 *
 * @type {Writable<ChannelFirestore[]|null>}
 */
const channels = writable();
const onetouchShops = writable([]);
//если мы еще не загрузили каналы выдает пустую строку, в остальных случаях отдает либо найденный канал либо нул
const showProjectSelectModal = writable(false);
const chosenChannel = writable('');
const recentChannels = writable([]);
const paymentProfiles = writable();
const paymentRecords = writable();
const invites = writable();
const corporateBills = writable();
const corporate = writable();

/**
 * Represents a user.
 * @typedef {Object} UserFirestore
 * @property {string} analytics - The analytics data.
 * @property {string} appToken - The app token.
 * @property {string} browserLang - The browser language.
 * @property {number} browserTZO - The browser timezone offset.
 * @property {string} counter - The counter value.
 * @property {number} creationTS - The creation timestamp.
 * @property {number} crm_id - The CRM ID.
 * @property {string} devToken - The development token.
 * @property {string} devToken2 - The second development token.
 * @property {string} email - The email address.
 * @property {string} ga_session_id - The Google Analytics session ID.
 * @property {string} id - The user ID.
 * @property {boolean} isOperator - Indicates if the user is an operator.
 * @property {number} onboardingType - The onboarding type.
 * @property {boolean} registrationMailSent - Indicates if the registration mail has been sent.
 * @property {boolean} trackVerification - Indicates if the verification is being tracked.
 * @property {boolean} trackVisit - Indicates if the visit is being tracked.
 * @property {boolean} trialUsed - Indicates if the trial has been used.
 * @property {boolean} verificationNeeded - Indicates if verification is needed.
 */

/**
 *
 * @type {Writable<UserFirestore|null>}
 */
const user = writable();
const balance = writable();
const projectUsers = writable();
const autoReplies = writable([]);
const repliesVariables = writable([]);
const alerts = writable();
const alertWarning = writable(false);
const trialBar = writable();
const trialRestrictionsModal = writable();

const logoutStore = writable(0)
const logoutStoreReadonly = derived(logoutStore, $a => $a);

export const currentPath = writable("")

let logoutCount = 0;
let listeners = [];
let channelsValue;
let shopsPromise;

let appClient = new AppClient({})
let firstTime = true;

const layoutLoad = writable();
const toolbarLoad = writable(false);

const getStorageKey = uid => `${uid}ChosenChannel`;
const getRecentStorageKey = uid => `${uid}RecentChannels`;

const setChosenChannel = (channel) => {
  const userLocal = get(user);

  if (userLocal?.id) {
    if (channel) {
      localStorage.setItem(getStorageKey(userLocal.id), channel.id);
    } else {
      localStorage.removeItem(getStorageKey(userLocal.id))
    }
  }
  chosenChannel.set(channel)
  channel && addRecentChannel(channel)
}

const initRecentChannels = () => {
  const userLocal = get(user);
  if (userLocal?.id) {
    let recentChannelsLocal = localStorage.getItem(getRecentStorageKey(userLocal.id));
    const channelsLocal = get(channels);
    if (recentChannelsLocal) {
      try {
        recentChannelsLocal = JSON.parse(recentChannelsLocal)
        if (Array.isArray(recentChannelsLocal) && recentChannelsLocal.length) {
          let result = []
          recentChannelsLocal.forEach(item => {
            const foundItem = channelsLocal.find(itemToFind => itemToFind.id === item)
            if (foundItem) {
              result.push(foundItem)
            }
          })
          recentChannels.set(result)
        }
      } catch (e) {
      }
    }
  }
}

const getUserLang = () => {
  const availableLangs = ['en', 'es', 'ru'];
  const userLocal = get(user);
  if (userLocal?.browserLang)
    for (let i = 0; i < availableLangs.length; i++) {
      if (userLocal?.browserLang.indexOf(availableLangs[i]) !== -1)
        return availableLangs[i]
    }
  return availableLangs[0]

}

const addRecentChannel = (channel) => {
  const userLocal = get(user);
  const recentChannelsLocal = get(recentChannels);
  const index = recentChannelsLocal.findIndex(item => item.id === channel.id)
  if (index !== -1) {
    recentChannelsLocal.splice(index, 1);
  }
  if (recentChannelsLocal.length === 5) {
    recentChannelsLocal.shift();
  }
  recentChannelsLocal.push(channel)
  recentChannels.set(recentChannelsLocal)
  localStorage.setItem(getRecentStorageKey(userLocal.id), JSON.stringify(recentChannelsLocal.map(item => item.id)))

}

const checkChosenChannel = (uid, channels) => {

  const localStorageKey = getStorageKey(uid);
  const chosenChannelKey = localStorage.getItem(localStorageKey);
  if (chosenChannelKey) {
    if (Array.isArray(channels) && channels.length) {
      const foundChannel = channels.find(item => item.id === chosenChannelKey)
      if (foundChannel) {
        chosenChannel.set(foundChannel)
        return
      }
    }
  }
  chosenChannel.set(null);

}
const checkLang = (userData) => {
  if (userData?.userToken && firstTime) {
    firstTime = false;
    let lang = navigator.language || navigator.userLanguage;

    if (userData.browserLang !== lang) {
      postRequest(
        `${defaultConst.functionsUrl}/updateUser`,
        {
          property: 'browserLang',
          value: lang,
          userToken: userData.userToken
        }
      );
    }

    let tz = new Date().getTimezoneOffset();
    if (userData.browserTZO !== tz) {
      postRequest(
        `${defaultConst.functionsUrl}/updateUser`,
        {
          property: 'browserTZO',
          value: tz,
          userToken: userData.userToken
        }
      );
    }

    if (userData?.userToken && !userData.trackVisit) {
      try {
        amplitude.logEvent('visit');
        postRequest(
          `${defaultConst.functionsUrl}/updateUser`,
          {
            property: 'trackVisit',
            value: true,
            userToken: userData.userToken
          }
        );
      } catch (e) {
      }
    }
  }
}

async function loginWithFallback(email, password) {
  const useDev = !!import.meta.env.VITE_ONETOUCH_USEDEV ?? false;
  return postRequest(
    `${defaultConst.functionsUrl}/fallbackLogin`,
    {
      email, password, useDev
    }
  );
}

async function loadAdditionalChannelInfo(channel) {
  let promises = [];
  if (channel.apiUrl) {
    promises.push(
      getRequest(`${channel.apiUrl}status`, {token: channel.token})
    );
    promises.push(
      getRequest(`${channel.apiUrl}me`, {token: channel.token})
    );
    let result = await Promise.all(promises);

    channel.authStatus = result[0].accountStatus || false;
    if (result[1] && result[1].id)
      channel.phone = result[1].id.split("@").shift();
    if (result[1] && result[1].avatar) channel.avatar = result[1].avatar;
  } else {
    channel.avatar = false;
    channel.phone = false;
  }
  return channel;
}

const collectGtmData = function () {
  let gtmData = {},
    cookies = document.cookie.split('; ');

  const fields = {
    utm_source: 'Source', utm_medium: 'Medium', utm_campaign: 'Campaign', utm_adgroup: 'AdGroup', creative: 'Ad',
    utm_term: 'Keyword', device: 'Device',
    utmcsr: 'First_Source', utmcmd: 'First_Medium', utmccn: 'First_Campaign', utmctr: 'First_Keyword'
  };
  for (let name of ['__gtm_campaign_url', '__gtm_referrer', 'initialTrafficSource', '_blog_landing_page']) {
    let cookie = cookies.find(item => item.includes(name + '='));
    if (cookie) {
      let data = decodeURIComponent(decodeURIComponent(cookie.replace(name + '=', ''))),
        parts = [];
      switch (name) {
        case '__gtm_campaign_url':
          parts = data.split('?')[1].split('&');
          break;
        case 'initialTrafficSource':
          parts = data.split('|').map(item => item.replace('(', '').replace(')', ''));
          break;
        case '__gtm_referrer':
          gtmData.Referer = data;
          break;
        case '_blog_landing_page':
          gtmData.Blog_Page = data;
          break;
      }
      for (let part of parts) {
        let field = fields[part.split('=')[0]];
        if (field) gtmData[field] = part.split('=')[1];
      }
    }
  }
  return gtmData;
};

const authChange = async (data, data2 = '', data3 = '') => {
  if (data && data.user) {
    let dbUser = {};
    let claims = (await data.user.getIdTokenResult())?.claims || {};
    amplitude.setUserId(data.user.uid);

    const getServiceData = async function () {
      let dbUser, token;
      while (!dbUser || !token) {
        dbUser = (await db.collection("users").doc(data.user.uid).get()).data();
        token = (await db.collection("tokens").doc(data.user.uid).get()).data();
      }
      return {dbUser, token: token.token};
    };

    try {
      getServiceData().then(async serviceData => {
        window.dataLayer.push({
          "event": "user_id_push",
          "client_email": data.user.email,
          "client_uid": data.user.uid,
          "is_admin": (String.toString(!!claims?.admin)),
        });

        if (serviceData.token && !serviceData.dbUser.trackVisit) {
          try {
            amplitude.logEvent('visit');
            postRequest(
              `${defaultConst.functionsUrl}/updateUser`,
              {
                property: 'trackVisit',
                value: true,
                userToken: serviceData.token
              }
            );

            postRequest(
              `${defaultConst.functionsUrl}/updateUser`,
              {
                property: 'gtmData',
                value: collectGtmData(),
                userToken: serviceData.token
              }
            );
          } catch (e) {
          }
        }

        if (serviceData.dbUser.analytics !== gaGlobal.vid) {
          await postRequest(
            `${defaultConst.functionsUrl}/updateUser`,
            {
              property: 'analytics',
              value: gaGlobal.vid,
              userToken: serviceData.token
            });
          if (_get_session_id()) await postRequest(
            `${defaultConst.functionsUrl}/updateUser`,
            {
              property: 'ga_session_id',
              value: _get_session_id(),
              userToken: serviceData.token
            });
        }
      });
    } catch (e) {}

    listeners.push(db.collection("users").doc(data.user.uid).onSnapshot((doc) => {
      let value = doc.data();
      dbUser = Object.assign(dbUser, {
        loggedIn: true,
        id: data.user.uid,
        name: value?.name || data.user.name,
        email: data.user.email,
      }, claims, (value ? {
        appToken: value.appToken,
        devToken: value.devToken,
        photoURL: data.user.photoURL,
        flag_ab: value.flag_ab,
        generalCompanyType: value.generalCompanyType,
        companyIndustry: value.companyIndustry,
        companyName: value.companyName,
        companySize: value.companySize,
        emailVerified: data.user.emailVerified,
        browserLang: value.browserLang,
        onboardingType: value.onboardingType,
        registrationMailSent: value.registrationMailSent,
        browserTZO: value.browserTZO,
        verificationNeeded: value && value.verificationNeeded !== undefined && value.verificationNeeded !== null ? value.verificationNeeded : false,
        isOperator: !!value.isOperator,
        migrated: value.migrated,
        agreementNeeded: value?.agreementNeeded,
        trackVisit: value?.trackVisit,
        trackVerification: value?.trackVerification,
        trialCreated: value?.trialCreated,
        analytics: value?.analytics,
        user_hints: value?.user_hints,
        isDeveloper: value?.isDeveloper,
        creationTS: value.creationTS
      } : {}));
      if (dbUser.agreementNeeded) {
        localStorage.setItem("agreementNeeded", dbUser.agreementNeeded);
      } else {
        localStorage.removeItem("agreementNeeded");
      }
      const toLog = {...{}, ...dbUser}
      if (dbUser.verificationNeeded && !dbUser.isOperator) {
        localStorage.setItem("verificationNeeded", dbUser.verificationNeeded);
      } else {
        localStorage.removeItem("verificationNeeded");
      }

      if (dbUser.isOperator) {
        localStorage.setItem("isOperator", '1')
      } else {
        localStorage.removeItem("isOperator")
      }
      user.set(dbUser);

      checkLang(dbUser)
      appClient.setCurrentUser(dbUser)
      //узнаемкорректный внутренний ид юзера вантача
      appClient.getCurrentUserOnetouch();

      const url2 = "https://app.1msg.io/echoping/?echo_header=Access-Control-Allow-Origin:*, Access-Control-Allow-Headers:*";
      timeout1 && clearInterval(timeout1);
      timeout1 = setInterval(
        () => {
          appClient.pingTestUrl(url2);
        }, 1000 * 60
      )
    }));

    listeners.push(db.collection("tokens").doc(data.user.uid).onSnapshot((doc) => {
      let value = doc.data();
      dbUser.userToken = value?.token;
      user.set(dbUser);
      checkLang(dbUser)
      appClient.setCurrentUser(dbUser)
    }));

    listeners.push(db.collection("balances").doc(data.user.uid).onSnapshot((doc) => {
      let value = doc.data();
      balance.set(value);
    }));


    localStorage.setItem("authUser", data.user.uid);
    if (!dbUser) {
      console.error(`no user ${data.user.uid}`);
      return;
    }

    listeners.push(db.collection("projects").where("users", "array-contains", data.user.uid)
      .onSnapshot((querySnapshot) => {
        let projectsArr = [];
        // let channelsObj = {};
        // let usersObj = {};
        querySnapshot.forEach((doc) => {
          let value = doc.data();
          projectsArr.push(value);
          // channelsObj = Object.assign(channelsObj, value.channels);
        });
        projects.set(projectsArr);
      }));

    listeners.push(db.collection("paymentProfiles").where("ownerId", "==", data.user.uid)
      .onSnapshot((querySnapshot) => {
        let paymentProfilesArr = [];
        // let channelsObj = {};
        // let usersObj = {};
        let currency = false;
        querySnapshot.forEach((doc) => {
          let value = doc.data();
          value.id = doc.id;
          if (!currency) currency = value.currency;
          paymentProfilesArr.push(value);
        });
        window.dataLayer.push({
          "client_currency": String.toString(currency)
        });
        paymentProfiles.set(paymentProfilesArr);
      }));

    listeners.push(db.collection("invites").where("receiverEmail", "==", data.user.email)
      .onSnapshot((querySnapshot) => {
        let invitesArr = [];
        querySnapshot.forEach(async (doc) => {
          let value = doc.data();
          value.id = doc.id;
          invitesArr.push(value);
        });
        invites.set(invitesArr);
      }));

      listeners.push(db.collection("channels").where("ownerId", "==", data.user.uid)
      .onSnapshot(async (querySnapshot) => {
        let channelsArr = [];
        let paidCount = 0;
        let oneTouchCount = 0;
        let trialAlert = {
          show: false
        }
        let trialIndex;
        let paidIndex;
        let noAutoCharge;
        let i = 0;
        querySnapshot.forEach((doc) => {
          let channel = doc.data();
          if (channel?.activeTill > Date.now()) paidCount++;
          if (channel?.appData) oneTouchCount++;
          if (channel?.srv === 'trial') trialIndex = i;
          if (channel?.paymentProfileId) paidIndex = i;
          if (channel?.noAutoCharge) noAutoCharge = i;
          channelsArr.push(channel);
          i++;
        });

        window.dataLayer.push({
          "channel_paid_count": String.toString(paidCount),
          "client_use_onetouch": String.toString(oneTouchCount),
        });

        // for (let channel of channelsArr) {
        //   channel = await loadAdditionalChannelInfo(channel);
        // }
        channelsValue = [...channelsArr];
        checkChosenChannel(data.user.uid, channelsArr)

        //<editor-fold desc="Условия отображения триалки">
        if (typeof trialIndex !== 'undefined') {
          let trialChannel = channelsArr[trialIndex];

          if (paidIndex !== undefined || noAutoCharge !== undefined ||  (channelsArr.length > 2 && trialChannel.activeTill < Date.now())) {
            channelsArr.splice(trialIndex, 1);
          } else {
            trialAlert = {
              show: true,
              expireDays: Math.ceil((trialChannel.activeTill - Date.now()) / 1000 / 60 / 60 / 24),
              phone: trialChannel.phone
            }
          }
        }
        channels.set(channelsArr);
        trialBar.set(trialAlert);
        initRecentChannels();
      }));

    listeners.push(db.collection("paymentRecords").where("ownerId", "==", data.user.uid)
      .onSnapshot(async (querySnapshot) => {
        let paymentRecordsArr = [];
        querySnapshot.forEach((doc) => {
          let payment = doc.data();
          payment.id = doc.id;
          paymentRecordsArr.push(payment);
        });
        paymentRecords.set([...paymentRecordsArr]);
      }));

    listeners.push(db.collection("alerts").where("isVisible", "==", 'true')
      .onSnapshot((docs) => {
        let alertsObj = {};
        docs.forEach((doc) => {
          let value = doc.data();
          alertsObj[doc.id] = value;
        });
        alerts.set(alertsObj);
      }));

    listeners.push(db.collection("corporateBills").where("ownerId", "==", data.user.uid)
      .onSnapshot(async (querySnapshot) => {
        let corporateBillsArr = [];
        querySnapshot.forEach((doc) => {
          let corporate = doc.data();
          corporate.id = doc.id;
          corporate.paymentSystem = 'moduleBank';
          corporateBillsArr.push(corporate);
        });
        corporateBills.set([...corporateBillsArr]);
      }));

    listeners.push(db.collection("corporates").doc(data.user.uid)
      .onSnapshot(async (doc) => {
        corporate.set(doc.data());
      }));
  } else {
    amplitude.reset();
    for (let unsubscribe of listeners) {
      unsubscribe();
    }
    firstTime = true;
    localStorage.removeItem("authUser");
    localStorage.removeItem("agreementNeeded");
    localStorage.removeItem("isOperator")
    user.set({loggedIn: false});
    projects.set([]);
    invites.set([]);
    channels.set([]);
    paymentRecords.set([]);
    corporateBills.set([]);
    corporate.set({});
    projectUsers.set([]);
    onetouchShops.set([]);
    channelsAdditionalInfo.set({});
    chosenChannel.set('');
    recentChannels.set([]);
    balance.set({});
    appClient.setCurrentUser({})
    appClient.setCurrentUserOnetouchId(null)
    channelsValue = [];
    logoutStore.set(++logoutCount);
  }
}

const getCorporateBillsSettings = async (channelId) => {
  const value = await db.collection("corporateBillsSettings").doc(channelId).get();
  return value.data();
};

callbacks.update(val => {
  val.onAuthStateChanged.push(authChange);
  return val;
});

const getChannelAdditionalInfo = (channelsToUpdate, updateStatus = true, updatePhone = true) => {
  let channelsToUpdateLocal = Array.isArray(channelsToUpdate) ? channelsToUpdate : [channelsToUpdate];
//пихаем в промисы запрос
  let promisesArr = [];
  channelsToUpdateLocal.forEach(activeChannel => {
    if (updateStatus && activeChannel?.apiUrl && activeChannel?.waba_info) {
      promisesArr.push(getRequest(`${activeChannel.apiUrl}status`, {
        token: activeChannel.token,
        full: true
      }).then(data => {
        if (data.message && data.message.indexOf('You have exceeded the limit') == -1) data.error = {message: data.message};
        if (!data.error) data.error = null;
        return !data.error ? [activeChannel.id, data] : [activeChannel.id, {accountStatus: '', error: data.error}]
      }, err => [activeChannel.id, {accountStatus: ''}]))
    }
    if (updatePhone && activeChannel?.apiUrl && activeChannel?.waba_info) {
      promisesArr.push(getRequest(`${activeChannel.apiUrl}me`, {
        token: activeChannel.token,
      }).then(data => {
        return !data.error ? [activeChannel.id, data] : [activeChannel.id, {id: '', name: ''}]
      }, err => [activeChannel.id, {id: '', name: ''}]))
    }
  })
  return Promise.all(promisesArr).then(result => {
    if (Array.isArray(result)) {
      const newAddInfo = {...get(channelsAdditionalInfo)};
      result.forEach(item => {
        for (let i in item[1]) {
          if (!newAddInfo[item[0]]) {
            newAddInfo[item[0]] = {};
          }
          newAddInfo[item[0]][i] = item[1][i]
        }
      })
      channelsAdditionalInfo.set(newAddInfo);
    }
    return result;
  })

  //если словили ошибку - ставим просто пустые стринги как минимум в нужные поля
  //запуск обновлений "снизу" из необходимых мест
  //возвращаем промис который резолвится после всех запросов
}
const getSpecialRate = async (id) => {
  let docRef = db.collection("specialRates").doc(id);
  try {
    let doc = await docRef.get();
    if (doc.exists) {
      return doc.data()
    } else {
      // doc.data() will be undefined in this case
      return false;
    }
  } catch (error) {
    console.error("Error getting document:", error);
    return false;
  }
}
const getMigrationToken = async (id) => {
  let docRef = db.collection("migrationTokens").doc(id);
  try {
    let doc = await docRef.get();
    if (doc.exists) {
      console.log("Document data:", doc.data());
      return doc.data()
    } else {
      // doc.data() will be undefined in this case
      return false;
    }
  } catch (error) {
    console.error("Error getting document:", error);
    return false;
  }
}
const updateChannels = (data) => {
  let oldChannels = [];
  for (let channel of channelsValue) {
    oldChannels.push(channel)
  }

  let newChannels = [...oldChannels];
  for (let channel of data) {
    let updateIndex = newChannels.findIndex((item) => {
      item.id === channel.id;
    })
    if (updateIndex !== -1) {
      newChannels[updateIndex] = channel;
    }
  }

  if (JSON.stringify(oldChannels) !== JSON.stringify(newChannels)) {
    channels.set(newChannels);
    channelsValue = newChannels;
  }

}

const registerUserInApp = async () => {
  return appClient.getTokenAndCheckInstances()
}

const getOnetouchShops = async () => {
  const res = get(onetouchShops)
  return res.length ? Promise.resolve(res) : loadOnetouchShops()
}
const loadOnetouchShops = async () => {
  if (!shopsPromise) {
    shopsPromise = appClient.getShops().then(data => {
      shopsPromise = null
      onetouchShops.set(data)
      return data
    }, err => {
      shopsPromise = null
    })
  }
  return shopsPromise
}

const getProjectUsers = async () => {
  const {url} = appClient.getCommonVars();
  return appClient.getRequest(`${url}/api/operators/operators_v2/`).then(data => {
    projectUsers.set(data)
  }, err => {
    projectUsers.set([])
  })
}

const addProjectUser = (email, role, shops_id) => {
  const {url} = appClient.getCommonVars();
  return appClient.postRequest(`${url}/api/operators/operators_v2/`, {
    email,
    role,
    shops_id,
    lang: getUserLang()
  }).then(data => {
    return data;
  }, err => {
    return err;
  })
}

const deleteProjectUser = (email) => {
  const {url} = appClient.getCommonVars();
  return appClient.deleteRequest(`${url}/api/operators/operators_v2/`, {}, {email}, true).then(data => {
    return data;
  }, err => {
    return err;
  })
}

const updateProjectUser = (data) => {
  const {url} = appClient.getCommonVars();
  return appClient.patchRequest(`${url}/api/operators/operators_v2/`, data).then(data => {
    return data;
  }, err => {
    return err;
  })
}

const getAutoReplies = async (channel) => {
  const {url, shopId} = appClient.getCommonVars(channel);
  return appClient.getRequest(`${url}/api/replies/auto_replies/`, {shop_id: shopId}).then(data => {
    autoReplies.set(data);
    return data
  }, err => {
    console.error(err);
    autoReplies.set([]);
    return [];
  })
}

const patchUpdateAutoReplies = (id, params) => {
  const {url} = appClient.getCommonVars();
  const link = `${url}/api/replies/auto_replies/`;
  return appClient.patchRequest(`${link}${id}/`, params, `${link}*/`).then(data => {
    return data
  }, err => {
    return err
  })
}

const patchUpdateRepliesGpt = (id, params, channel) => {
  const {url, shopId} = appClient.getCommonVars(channel);
  const link = `${url}/api/replies/gpt/`;
  if (id) {
    return appClient.patchRequest(`${link}${id}/`, {...params, shop_id: shopId}, `${link}*/`).then(data => {
      return data
    }, err => {
      return err
    })
  } else {
    return appClient.postRequest(`${link}?shop_id=${shopId}`, {...params, shop_id: shopId}, `${link}*/`).then(data => {
      return data
    }, err => {
      return err
    })
  }
}

const getRepliesVariables = (channel) => {
  const {url, shopId} = appClient.getCommonVars(channel);
  return appClient.getRequest(`${url}/api/replies/variables/`, {shop_id: shopId}).then(data => {
    repliesVariables.set(data);
    return data
  }, err => {
    console.error(err);
    repliesVariables.set([]);
    return []
  });
}
const toggleTrialRestrictionsModal = (value) => {
  if (typeof value === 'undefined') {
    value = !trialRestrictionsModal.get();
  }
  trialRestrictionsModal.set(value);
}


/**
 * Adds a channel to a project.
 *
 * @param {string} projectId - The ID of the project.
 * @param {string} channelName - The name of the channel.
 * @param {string} phone - The phone number associated with the channel.
 * @param {boolean} [trial=false] - Indicates if the channel should be created as a trial.
 * @param {boolean} [force=false] - Forces the creation of the channel without phone. Usually needed for first channel creation
 * @param {boolean} [showErrors=false] - whether to display errors in toast automatically or not
 *
 * @return {Promise<any>} A promise representing the result of the operation.
 */
async function addChannel(projectId, channelName, phone, trial = false, force = false, showErrors = true) {

  let params = {
    projectId: projectId,
    userToken: get(user).userToken,
    name: channelName,
    phone: phone,
  };
  if (force) params['force'] = force
  if (trial) {
    params['trial'] = trial
    if (localStorage.getItem('ssp'))
      params['ssp'] = localStorage.getItem('ssp')
    //костылинг уровня атланта на установку доступа юзера
      if (localStorage.getItem('sdv')){
        postRequest(
          `${defaultConst.functionsUrl}/setDev`,params
        ).then(async (data) => {
          localStorage.getItem('sdv') && localStorage.removeItem('sdv')
        })
      }
  }
  return postRequest(
    `${defaultConst.functionsUrl}/createChannel`,
    params
  ).then(async (data) => {
    if (!data || data.error) {
      if (showErrors)
        await addToast({
          title: get(_)("error"),
          message: data.error || get(_)("something_went_wrong")
        });
      return Promise.reject(false);
    } else {
      // if (!trial&&!force) await addToast({title: get(_)("saved")});
      localStorage.getItem('ssp') && localStorage.removeItem('ssp')
      return data
    }
  }, async (error) => {
    if (showErrors)
      await addToast({
        title: get(_)("error"),
        message: error.toString() || get(_)("something_went_wrong")
      });
    return Promise.reject(error)
  });

}


/**
 * A function that takes data containing editable properties.
 * @param {string} channelId - channelId
 * @param {("name"|"description"|"trialAvailableFlag"|"phone")} property - The data object containing editable properties.
 * @param {*} value - The data object containing editable properties.
 * @returns {Promise<* | {error: string} | undefined>}
 */
async function updateChannel(channelId, property, value) {
  const params = {channelId, property, userToken: get(user).userToken}
  if (value) {
    params['value'] = value;
  }
  return postRequest(
    `${defaultConst.functionsUrl}/updateChannel`,
    params
  ).then(async (data) => {
    if (!data || data.error) {
      await addToast({
        title: get(_)("error"),
        message: data.error || get(_)("something_went_wrong")
      });
      return Promise.reject(false);
    } else {
      await addToast({title: get(_)("saved")});
      return data
    }
  }, async (error) => {
    await addToast({
      title: get(_)("error"),
      message: error.toString() || get(_)("something_went_wrong")
    });
    return Promise.reject(error)
  });

}


/**
 * checks whether user is first time comer/updater, then it creates channel more simply and navigates to it. If User
 * already have unpaid first channel (never paid), then it redirects to it
 *
 * @param {Function} callbackFn - The callback function to be executed if it not the first time channel
 * @returns {Promise<*>} - if channel is not present, promise is retured that resolves upon channel creation
 */
function addChannelFirstTimeHelper(callbackFn = null) {
  const channelsLocal = get(channels)?.filter(item => item.srv !== 'trial')
  const paymentRecordsLocal = get(paymentRecords)?.filter(item => item.paymentSystem !== "demo")
  if (channelsLocal?.length > 1 || paymentRecordsLocal.length) {
    callbackFn && callbackFn();
  } else if (channelsLocal?.length > 0 && !paymentRecordsLocal.length) {
    navigate(`/channel/${channelsLocal?.[0]?.id}`)
  } else {
    const projectLocal = get(projects)
    return addChannel(projectLocal?.[0]?.id, 'First channel', '', false, true).then(({result}) => {
      if (result)
        navigate(`/channel/${result.channelId}`)
    })
  }
}

function isUserCreatedAfter1July2024() {
  const userLocal = get(user);
  return userLocal.creationTS > 1719792000000;
}

export {
  projects,
  channels,
  invites,
  paymentProfiles,
  paymentRecords,
  corporateBills,
  getCorporateBillsSettings,
  corporate,
  projectUsers,
  alerts,
  alertWarning,
  appClient,
  chosenChannel,
  onetouchShops,
  recentChannels,
  balance,
  showProjectSelectModal,
  setChosenChannel,
  getSpecialRate,
  getProjectUsers,
  addProjectUser,
  addChannel,
  updateChannel,
  addChannelFirstTimeHelper,
  deleteProjectUser,
  updateProjectUser,
  getOnetouchShops,
  autoReplies,
  getAutoReplies,
  patchUpdateAutoReplies,
  patchUpdateRepliesGpt,
  repliesVariables,
  getRepliesVariables,
  user,
  layoutLoad, toolbarLoad,
  channelsAdditionalInfo,
  logoutStoreReadonly,
  getChannelAdditionalInfo,
  updateChannels,
  registerUserInApp,
  loginWithFallback,
  trialBar,
  trialRestrictionsModal,
  toggleTrialRestrictionsModal,
  isUserCreatedAfter1July2024
}
