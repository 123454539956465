<script>
  import {Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Button} from "sveltestrap";
  import {onMount, getContext, onDestroy, beforeUpdate, afterUpdate, tick} from "svelte";
  import {_, date} from "svelte-i18n";

  import {channels,  user,addChannel} from "../../helpers/store";
  import {postRequest, getRequest, promisifyStore} from "../../helpers/utils";
  import {layout} from "../../services/loaders";
  import {navigate} from "svelte-routing";


  export let show;
  export let project;
  export let onCloseClick;

  let localShow = false;

  let activeUser = {}, phoneFailed = false;

  const unsub = user.subscribe((value) => {
    activeUser = value;
  });

  let loading = false;
  let channelName = "New channel";
  let phone;
  let phoneInput = false, iti;

  onMount(async () => {
  });

  onDestroy(() => {
    unsub();
    phoneInput = false;
  })

  const changeShow = async (value)=>{
    if(value){
      try {
        layout(true);
      } catch (e) {
        console.error(e)
      }
    }
    layout(false);
    localShow = value;
  }

  afterUpdate(async () => {
    if(!localShow){
      phoneInput = false;
    }else if(!phoneInput){
     /* console.log('afterUpdate');
        setTimeout(() => {
          phoneInput = document.querySelector("#phone");
          iti  = intlTelInput(phoneInput, {
            utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/8.4.6/js/utils.js"
          });
        }, 20)*/

      }

  });


  const addChannelLocal = async () => {
    if(!phone || loading) return;
    loading = true;

    return addChannel(project.id,channelName,phone).then(result=>{
      loading = false;
      window.dataLayer.push({'event': 'onboarding_create_channel', "channel_id": result.result.channelId});
      onCloseClick();
      show = false;
      return setTimeout(() =>{  navigate(`/channel/${result.result.channelId}`)}, 50);
    },error=>{
      loading = false;
    })
  };

  $: if (typeof show !== 'undefined') {
    changeShow(show);
  }


</script>

<div class="add-event-modal">
  <Modal
      isOpen={localShow}
      fade={true}
      toggle={onCloseClick}
      size="md"
      class="modal-add channels"
      scrollable
      centered
  >

    <ModalHeader toggle={onCloseClick}>
      <span class="">{$_('channel.add_modal.channel_add')}</span>
    </ModalHeader>
      <ModalBody>
        <div class="container-fluid">
          <form id="add_channel" on:submit|preventDefault={addChannelLocal} class="row">
            <div class="col-7 px-0">
              <div class="form-group mb-4">
                <label  class="fg-solitude-grey font-size-14 fw-600" for="name">{$_('channel.add_modal.channel_name')}</label>
                <input type="text" bind:value={channelName} id="name"
                       placeholder={$_('channel.add_modal.channel_name_placeholder')} class="form-control" required>
              </div>

              <div class="form-group mb-4">
                <label class="fg-solitude-grey font-size-14 fw-600 w-100"
                       for="phone">{$_('channel.add_modal.phone')}</label>
                <input bind:value={phone} class="form-control" id="phone"
                       on:change={()=> {phoneFailed = false}} pattern='^\+[0-9]&#123;7,14&#125;$' placeholder="+1" required>
                {#if phoneFailed}
                  <div class="text-danger font-size-11">{$_('channel.add_modal.phone_check_error')}</div>
                {/if}
              </div>
              <p class="font-size-11 p-2 rounded bg-soft bg-solitude-grey">{@html $_('channel.add_modal.phone_text')}</p>


              <!--<p class="font-size-16">{$_('channel.add_modal.channel_about_to_add')}</p>
              <p class="font-size-18 semi-bold">
                <i class="bx bx-check font-size-24 text-success"></i>
                {project.name || ''}
              </p>-->
            </div>
            <div class="col-5">
              <div class="text-center">
                <img src="/assets/images/person_edit.png" alt="person" class="img-fluid "/>
              </div>


              <div class="mt-3">
                <a class="font-size-10" target="_blank" href="https://faq.whatsapp.com/196737011380816/?cms_platform=android&locale=en_US">
                  <i class="font-size-14 dripicons-information me-2 fg-solitude-grey text-soft"></i>{$_('channel.add_modal.backup')}</a>
              </div>
              <div class="mt-1">
                <a class="font-size-10" target="_blank" href="https://faq.whatsapp.com/605464643328528/?cms_platform=android&locale=en_US">
                  <i class="font-size-14 dripicons-question me-2 fg-solitude-grey text-soft"></i>{$_('channel.add_modal.delete')}</a>
              </div>


            </div>
          </form>
        </div>


        <!--        <div class="info-block">-->
        <!--          <p>На проекте выбрана оплата с периодом: <b>месяц</b></p>-->
        <!--          <p>После создания канала с Вас будет списано:</p>-->
        <!--          <p class="price">12 <span>$</span></p>-->
        <!--        </div>-->
        <!--{$_('channel.add_modal_text')}-->
        <!--<Button color="success" on:click={addChannel} class="btn-sm">-->
        <!--    <i class="mdi mdi-check"/>-->
        <!--</Button>-->
        <!--<Button-->
        <!--    type="reset"-->
        <!--    color="danger"-->
        <!--    class="btn-sm"-->
        <!--    on:click={onCloseClick}-->
        <!--&gt;-->
        <!--    <i class="mdi mdi-close"/>-->
        <!--</Button>-->

      </ModalBody>
      <ModalFooter class="mx-0">
        <div class="container-fluid">
          <div class="row">
            <div class="col-7 p-0 d-flex justify-content-end">
              <button class="btn btn-outline-solitude-grey mr-3"  on:click={onCloseClick}>{$_('cancel')}</button>
            </div>
            <div class="col-5">
              <button class="btn btn-lucky-green" type="submit" form="add_channel" >
                {#if loading}
                  <i class="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                {/if}
                {$_('channel.add_modal.channel_add_button')}
              </button>
            </div>
          </div>
        </div>
      </ModalFooter>
  </Modal>
</div>
