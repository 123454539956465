<script>
  import {Container, Row, Col, Card, CardBody} from "sveltestrap";
  import {_} from "svelte-i18n";
  import {Link} from "svelte-routing";
  import DownloadSDK from "./DownloadSDK.svelte";
  import DescriptionSDK from "./DescriptionSDK.svelte";
  import CodeExampleSDK from "./CodeExampleSDK.svelte";

  export let params;

  const programmingLanguageArr = [
    {key: 'java', title: 'Java'},
    {key: 'javascript', title: 'JavaScript'},
    {key: 'php', title: 'PHP'},
    {key: 'c++', title: 'C++'},
    {key: 'c-sharp', title: 'C#'},
    {key: 'python', title: 'Python'},
    {key: 'typescript', title: 'TypeScript'},
    {key: 'c', title: 'C'}
  ];
</script>

<div class="page-content">
  <Container fluid>
    {#if !params?.key}
      <div class="header-title">{$_('menuitems.sdk')}</div>
      <Row>
        {#each programmingLanguageArr as item}
          <Col sm={6} md={4} lg={4} xl={3}>
            <Link to="/sdk-generator/{item.key}">
              <div class="card CardLink">
                <CardBody>
                  <img src="/assets/images/SDKGenerator/{item.key}.svg"
                    alt={item.title} class="mb-3 mx-auto d-block"/>
                  <div class="font-size-16 fw-600 text-center">
                    {item.title}
                  </div>
                </CardBody>
              </div>
            </Link>
          </Col>
        {/each}
      </Row>
    {:else}
      <Card>
        <CardBody>
          <DescriptionSDK {...params}/>
          <CodeExampleSDK key={params.key}/>
          <DownloadSDK key={params.key}/>
        </CardBody>
      </Card>
    {/if}
  </Container>
</div>

<style>
  .CardLink {
    color: var(--bs-body-color);
  }

  .CardLink:hover {
    box-shadow: 0px 0px 0px 2px #23B16D;
  }
</style>
