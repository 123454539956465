<script>
    import firebase from "firebase/app";
    import {Col, Container, Row, Input, Table} from "sveltestrap";
    //Import Breadcrumb
    import Breadcrumb from "../../common/Breadcrumb.svelte";
    //Import Cards

    import {Link, navigate} from "svelte-routing";
    import CardChannel from "./CardChannel.svelte";
    import {Card, CardBody} from "sveltestrap";
    import {channels, paymentProfiles} from "../../helpers/store";
    import {_} from "svelte-i18n";
    import {onDestroy, onMount} from "svelte";
    import {toolbar} from "../../services/loaders";
    import Pagination from "../../Components/Pagination/Pagination.svelte";
    import {addToast} from "../../helpers/toast";
    import {postRequest} from "../../helpers/utils";
    import {defaultConst} from "../../common/constants";
    import Spinner from "../../Components/Spinners/SpinnerComponent.svelte";
    import moment from "moment";

    const colors = ["bg-success", "bg-info", "bg-warning", "bg-danger"];

    let channelsList = [];
    let paymentProfilesList = [];
    let channelsFiltered = [];
    let searchText = '';
    let paginationParams = {
        active: false,
        count: 0,
        page: 0,
        pageSize: 20,
    };

    const unsub = channels.subscribe(async (local) => {
        channelsList = local || [];
        paginationParams.count = channelsList.length;
        prepareChannels();
    });

    const unsub2 = paymentProfiles.subscribe(async (local) => {
        paymentProfilesList = local || [];
        prepareChannels();
    });

    function prepareChannels() {
        channelsList = channelsList.map((item) => {
            if(item.paymentProfileId){
                item.subscription = paymentProfilesList.find((profile) => {return profile.id === item.paymentProfileId})
            }
            return item;
        });

        if (!searchText.length && paginationParams.page === 0) {
            updateFilteredChannels();
        }
    }

    function updateFilteredChannels() {
        if (channelsList.length > paginationParams.pageSize * 4) {
            paginationParams.active = true;
        }
        let filteredArr = [...channelsList];

        if (searchText.length) {
            let query = searchText.toLowerCase();
            filteredArr = channelsList.filter((item) => {
                return (item.id.toLowerCase().indexOf(query) !== -1 ||
                    item.name && item.name.toLowerCase().indexOf(query) !== -1)
            });
        }

        if (paginationParams.page) {
            filteredArr = filteredArr.slice(paginationParams.page, paginationParams.page + paginationParams.pageSize)
        }
        channelsFiltered = filteredArr;
        return channelsFiltered;
    }

    $: if(searchText) updateFilteredChannels();

    onMount(() => {
      navigate("/billing");
    });

    onDestroy(() => {
        unsub();
        unsub2();
    });
</script>

<div class="page-content">
    <Container fluid>
        <div class="header-title">{$_("subscription.choose_channel")}</div>
        <Card>
            <CardBody>
                <div class="table-search">
                    <Input type="text" bind:value={searchText} placeholder={$_('search')}/>
                </div>
                <div class="table-responsive">
                    <Table class="align-middle table-nowrap table-check table-project">
                        <thead>
                        <tr>
                            <th class="align-middle id">{$_("channels.table.id")}</th>
                            <th class="align-middle">{$_('channels.table.name')}</th>
                            <th class="align-middle">{$_('channels.table.paid_till')}</th>
                            <th class="align-middle">{$_('channels.table.subscription')}</th>
                            <th class="align-middle">{$_('channels.table.status')}</th>
                            <th class="align-middle">{$_('channels.table.actions')}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {#each channelsFiltered as channel}
                        <tr>
                            <td>
                                <Link class="text-body fw-bold whatsapp pt-3" to="/channels-payments/{channel.id}">
                                    <i class="bx bxl-whatsapp"></i>
                                    {channel.id}
                                </Link>
                            </td>
                            <td class=" pt-3">
                                {channel.name || '-'}
                            </td>
                            <td class=" pt-3">
                                {channel.activeTill ? moment(parseInt(channel.activeTill)).format('DD.MM.YYYY') : '-'}
                            </td>
                            <td class=" pt-3">
                                {channel.subscription && channel.subscription.rate || '-'}
                            </td>
                            <td class=" pt-3">
                                <span class={channel.stopped && 'badge-status danger' || channel.subscription &&'badge-status success' || 'badge-status warning'}>
                                {channel.stopped && 'stopped' || channel.subscription && 'active' || 'no subscription'}
                                </span>
                            </td>
                            <td class=" pt-3">
                                <Link to="/channels-payments/{channel.id}">
                                {channel.subscription ? $_('subscription.manage') : $_('subscription.subscribe') }
                                </Link>
                            </td>
                        </tr>
                      <!--  <Col xxl="2" xl="3" md="4">
                            <CardChannel channel={channel}/>
                        </Col>-->
                            {/each}
                        </tbody>
                    </Table>
                </div>
            </CardBody>
        </Card>

        {#if paginationParams.active}
            <Row>
                <Col lg={12}>
                    <Pagination {...paginationParams}/>
                </Col>
            </Row>
        {/if}
    </Container>
</div>
