<script>
    import {Card, CardBody} from "sveltestrap";
    import ReceiptsModal from "../../Components/Modals/ReceiptsModal.svelte";
    import {_} from "svelte-i18n";
    import {defaultConst} from "../../common/constants";
    import {postRequest} from "../../helpers/utils";
    import {isUserCreatedAfter1July2024} from "../../helpers/store";
    import {addToast} from "../../helpers/toast";
    import {subscribeProcess} from "../../helpers/subscribe";
    import YookassaModal from "../../Components/Modals/YookassaModal.svelte";

    export let pricing, channel, user, checkBillingDetails, lang, features = [], marker;

    let loading = false,
        yooModal = false,
        unpaidReceipts = false,
        receiptsModal = false,
        loadingCorporateInvoiceHosting = false;

    async function subscribe() {
        if (loading) return;
        loading = true;
        let params = Object.assign({
            id: channel.id,
            userToken: user.userToken,
            currency: pricing.price.currency,
            rate: pricing.price.rate,
            org: pricing.price.org,
            provider: pricing.payment.id,
        });

        let result = await subscribeProcess(params);

        loading = false;
        if(result.error?.urls) {
          unpaidReceipts = result.error.urls;
          receiptsModal = true;
        } else if (params.provider === "yookassa" && result) {
            return toggleYoomodal(true, result)
        }
    }

    function toggleYoomodal(active = false, yooToken = false) {
        if (active && yooToken) {
            yooModal = true;
            const checkout = new window.YooMoneyCheckoutWidget({
                confirmation_token: yooToken, //Токен, который перед проведением оплаты нужно получить от ЮKassa
                return_url: `https://my.1msg.io/billing`, //Ссылка на страницу завершения оплаты
                error_callback(error) {
                    console.error(error);
                    //Обработка ошибок инициализации
                }
            });

            //Отображение платежной формы в контейнере
            setTimeout(async () => {
                checkout.render('yookassa-form');
            }, 300);
        } else {
            yooModal = false;
        }
    }

    function corporateInvoiceHosting() {
        let data = {
            url: `${defaultConst.functionPaymentProxyUrl}/corporate-invoice/hosting`,
            params: {
                uid: user.id,
                rate: pricing.price.rate,
                currency: pricing.price.currency,
                channelId: channel.id,
                userToken: user.userToken
            }
        };
        if (isUserCreatedAfter1July2024()) {
            data.params.org = pricing.price.org;
        }
        if (checkBillingDetails(data)) {
            loadingCorporateInvoiceHosting = true;
            postRequest(data.url, data.params, true).then(res => {
                loadingCorporateInvoiceHosting = false;
                if (res.ok) {
                    res.blob().then(blob => {
                        const url = window.URL.createObjectURL(blob);
                        const link = document.createElement('a');
                        link.href = url;
                        let filename = res.headers.get('Content-Disposition').split(';').pop().split('"')[1];
                        link.download = `corporate-invoice-${filename}`;
                        document.body.appendChild(link);
                        link.click();
                        window.URL.revokeObjectURL(url);
                        link.remove();
                        addToast({
                            title: "Успех",
                            message: "Счет на оплату создан"
                        });
                    });
                } else {
                    addToast({
                        title: $_("error"),
                        message: res.error || $_("something_went_wrong"),
                        dismissible: true,
                        timeout: 4_000
                    });
                }
            });
        }
    }
</script>

{#if lang === 'ru' && isUserCreatedAfter1July2024()}
    <Card class="w-100">
        <CardBody class="p-0 h-100 d-flex flex-column justify-content-between">
            <div class="mb-4">
                <div class="d-flex justify-content-between pt-3 pe-3 mb-4">
                    <div>
                        <div class="font-size-16 fw-600 px-3 mb-1 position-relative {marker ? 'GreenBlock' : ''}">{$_(pricing.title)}</div>
                        <div class="text-muted font-size-12 px-3">{$_(pricing.rate)}</div>
                    </div>
                    <div class="maxWidth_100">
                        <img class="img-fluid" src={pricing.payment.logo} alt="logo"/>
                    </div>
                </div>
                <div class="px-4">
                    <div class="text-center mb-4">
                        <div>
                            <span class="font-size-20 align-top colorBlueMuted">{$_(pricing.price.currency)}</span>
                            <span class="text-primary fontSize_40">{pricing.price.value}</span><span class="font-size-16 fw-600 colorBlueMuted">/ {$_(pricing.duration)}</span>
                        </div>
                        {#if pricing.discount}
                            <div class="colorBlueMuted font-size-13 CardPricing-savings">~ {Math.round(pricing.price.value / 12)} р / месяц</div>
                        {/if}
                    </div>
                    <ul class="m-0 pt-2 ps-0 pe-3 CardPricing-list">
                        {#each features as feature}
                            <li class="mb-3 position-relative">
                                {#if feature.active}
                                    <i class="far fa-check-square font-size-16 text-success position-absolute start-0"/>
                                {:else}
                                    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg" class="position-absolute start-0">
                                        <rect y="0.000366211" width="17" height="17" rx="4" fill="#EFF2F7"/>
                                        <path d="M12.0026 6.55285L11.3922 5.9425L8.97248 8.36225L6.55273 5.9425L5.94238 6.55285L8.36213 8.9726L5.94238 11.3923L6.55273 12.0027L8.97248 9.58294L11.3922 12.0027L12.0026 11.3923L9.58282 8.9726L12.0026 6.55285Z" fill="white"/>
                                    </svg>
                                {/if}
                                <span class="font-size-14 {feature.active ? 'text-muted' : 'textDisabled'}">{$_(feature.text)}</span>
                            </li>
                        {/each}
                    </ul>
                </div>
            </div>
            <div class="d-flex align-items-center flex-column gap-2 pb-4 px-4 CardPricing-btns">
                <button type="button" class="btn btn-success font-size-16 w-100"
                    on:click={subscribe} disabled={loading || loadingCorporateInvoiceHosting}>
                    {#if loading}
                        <i class="bx bx-loader bx-spin font-size-16 align-middle me-2"/>
                    {/if}
                    Оформить подписку
                </button>
                <button class="btn btn-primary font-size-16 w-100" type="button"
                    on:click={corporateInvoiceHosting} disabled={loadingCorporateInvoiceHosting || loading}>
                    {#if loadingCorporateInvoiceHosting}
                        <i class="bx bx-loader bx-spin font-size-16 align-middle me-2"/>
                    {/if}
                    {$_('payments.pay_by_invoice')}
                </button>
            </div>
        </CardBody>
    </Card>
{:else}
    <Card class="plan-box w-100">
        <CardBody class="p-3">
            <div class="d-flex">
                <div class="flex-grow-1">
                    <h5>{$_(pricing.title)}</h5>
                    <p class="text-muted">{$_(pricing.description)}</p>
                </div>
                <div class="ms-3">
                    <div class="current-logo text-center">
                        <img class="img-fluid" src={pricing.payment.logo} alt="logo"/>
                    </div>
                </div>
            </div>
            <div class="py-4">
                <h2 class="fg-royal-blue display-6">
                    <sup style="top: -1.25em; left: 0.4em" class=" font-size-20 text-soft text-royal-blue"> {$_(pricing.price.currency)}</sup>
                    {pricing.price.value}/<span class="font-size-18 text-soft text-royal-blue fw-500">{$_(pricing.duration)}</span>
                </h2>
            </div>
        <div class="plan-features mt-2">
            {#each pricing.features as feature}
            <p>
                <i class="far fa-check-square font-size-16 me-1"/>{" "}
                {$_(feature)}
            </p>
            {/each}
        </div>
            {#if channel.srv !== 'trial'}
                <div class="d-grid gap-2">
                    <button class="mt-2 btn btn-lucky-green mb-2" type="button"
                        on:click={subscribe} disabled={loading || loadingCorporateInvoiceHosting}>
                        {#if loading}
                            <i class="bx bx-loader bx-spin font-size-16 align-middle me-2"/>
                        {/if}
                        {$_(pricing.btn)}
                    </button>
                    {#if lang === 'ru'}
                        <button class="btn btn-lucky-primary" type="button"
                            on:click={corporateInvoiceHosting} disabled={loadingCorporateInvoiceHosting || loading}>
                            {#if loadingCorporateInvoiceHosting}
                                <i class="bx bx-loader bx-spin font-size-16 align-middle me-2"/>
                            {/if}
                            {$_('payments.pay_by_invoice')}
                        </button>
                    {/if}
                </div>
            {/if}
        </CardBody>
    </Card>
{/if}

{#if receiptsModal}
    <ReceiptsModal show={receiptsModal} {channel} data={unpaidReceipts} toggleModal={() => receiptsModal = !receiptsModal}/>
{/if}

{#if pricing.payment.id == 'yookassa'}
    {#if yooModal}
        <YookassaModal show={yooModal} {toggleYoomodal}/>
    {/if}
{/if}

<style>
    .maxWidth_100 {
        max-width: 100px;
    }

    .fontSize_40 {
        font-size: 40px;
    }

    .colorBlueMuted {
        color: #9CAAFF;
    }

    .CardPricing-savings {
        margin-top:-5px;
        margin-bottom: -14.5px;
    }

    .CardPricing-list {
        list-style-type: none;
    }

    .CardPricing-list li {
        padding-left: 30px;
    }

    .CardPricing-list i, .CardPricing-list svg {
        top: 2px;
    }

    .CardPricing-list .textDisabled {
        color: #CED4DA;
    }

    .CardPricing-btns .btn {
        padding: 11px;
        max-width: 230px
    }

    .GreenBlock::before {
        content: "";
        position: absolute;
        width: 6px;
        height: 100%;
        top: 0;
        left: 0;
        background-color: #4FC18A;
    }
</style>
