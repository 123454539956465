<script>
  import {Link, navigate} from "svelte-routing";
  import {Card, CardBody, Col, Container, Row, Modal, ModalHeader, ModalBody} from "sveltestrap";
  import {_} from "svelte-i18n";

  import {toolbar} from "../../services/loaders";
  import {addToast} from "../../helpers/toast";
  import {DEFAULT_ADMINISTRATOR_DASHBOARD, DEFAULT_OPERATOR_DASHBOARD, defaultConst} from "../../common/constants";
  import {channels, updateChannel, user} from "../../helpers/store";
  import {auth} from '../../helpers/firebase';
  import {postRequest, promisifyStore} from "../../helpers/utils";

  import {onDestroy, afterUpdate} from "svelte";
  import {copyText} from "../../helpers/utils";
  import * as amplitude from "@amplitude/analytics-browser";

  import Spinner from "../../Components/Spinners/SpinnerComponent.svelte";

  let activeUser = false;
  let saving = false;
  let loading = true;
  let trialModal = false;
  let inputs;
  let channelId = false;

  const unsub = user.subscribe((value) => {
    let callBind = false;
    if (!activeUser && value) {
      callBind = true;
    }

    if (value?.id) {
      if (!value.verificationNeeded && !activeUser?.id) {
        return setTimeout(() => {

          if (value.isOperator) {
            loading = false;
            navigate(DEFAULT_OPERATOR_DASHBOARD);
          } else {
            promisifyStore(channels).then(data => {
              loading = false;
              if (data?.length && data[0]?.id) {
                navigate(`/channel/${data[0].id}`);
              } else {
                navigate(DEFAULT_ADMINISTRATOR_DASHBOARD);
                loading = false;
              }
            }, err => {
              navigate(DEFAULT_ADMINISTRATOR_DASHBOARD);
              loading = false;
            })
          }

        }, 30);
      } else {
        loading = false;
      }
    }

    activeUser = value;

    if (callBind) {
      setTimeout(bind, 50);
    }


  });

  onDestroy(() => {
    unsub();
  })

  afterUpdate(() => {
    if (activeUser?.id && !inputs) {
      bind();
    }
  })

  const checkCode = async () => {
    if (saving) return;
    saving = true;
    toolbar(saving)

    let code = '';
    if (!inputs) {
      const form = document.querySelector('form')
      inputs = form.querySelectorAll('input')
    }
    inputs.forEach(input => {
      code += input?.value
    });
    let params = {
      userToken: activeUser?.userToken,
      code: code,
      mode: 'email'
    };

    try {
      params.aid = amplitude.getDeviceId();
    } catch (e) {
    }

    let result;

    try {
      result = await postRequest(
        `${defaultConst.functionsUrl}/verifyWithCode`,
        params
      );
    } catch (e) {
    }
    saving = false;
    toolbar(saving)
    if (!result || result.error) {
      await addToast({
        title: $_("error"),
        message: result.error || $_("something_went_wrong"),
        dismissible: true,
        timeout: 4_000,
      });
    } else {
      await addToast({title: $_("saved")});
      dataLayer.push({event: "verification_success"});
      loading = false;
      saving = false;
      if (activeUser.isOperator) {
        navigate(DEFAULT_OPERATOR_DASHBOARD);
      } else if (result?.channelId) {
        navigate(`/inbox`);
      } else {
        navigate(DEFAULT_ADMINISTRATOR_DASHBOARD);
      }
    }
  };

  const acceptTrial = async () => {
    if (loading) return;
    loading = true;
    try {
      if (channelId)
        await updateChannel(channelId, 'trialAvailableFlag', true)

      loading = false;

      navigate(`/channel/${channelId}`);

    } catch (e) {
      console.error(e)
    }
  }
  const handleLogOut = () => {
    auth.signOut().then(
      function () {
        // Sign-out successful.
        setTimeout(() => {
          navigate('/login')
        }, 50);
      },
      function (error) {
        // An error happened.
        console.warn('error on logout', error.message);
      }
    );
  };
  const resend = async () => {
    if (saving) return;
    saving = true;
    toolbar(saving)

    const params = {
      userToken: activeUser?.userToken,
      mode: 'email'
    };

    let result;

    try {
      result = await postRequest(
        `${defaultConst.functionsUrl}/resendVerificationCode`,
        params
      );
    } catch (e) {
    }
    saving = false;
    toolbar(saving)
    if (!result || result.error) {
      addToast({
        title: $_("error"),
        message: result.error || $_("something_went_wrong"),
        dismissible: true,
        timeout: 4_000,
      });
    } else {
      addToast({
        title: $_("sent"),
        dismissible: true,
        timeout: 3_000,
      });
    }
  };


  function bind() {
    const form = document.querySelector('form')
    inputs = form.querySelectorAll('input')
    const KEYBOARDS = {
      backspace: 8,
      arrowLeft: 37,
      arrowRight: 39,
    }

    function handleInput(e) {
      const input = e.target
      const nextInput = input.nextElementSibling
      if (nextInput && input.value) {
        nextInput.focus()
        if (nextInput.value) {
          nextInput.select()
        }
      }
    }

    function handlePaste(e) {
      e.preventDefault()
      const paste = e.clipboardData.getData('text')
      inputs.forEach((input, i) => {
        input.value = paste[i] || ''
      })
    }

    function handleBackspace(e) {
      const input = e.target
      if (input.value) {
        input.value = ''
        return
      }

      input.previousElementSibling?.focus()
    }

    function handleArrowLeft(e) {
      const previousInput = e.target.previousElementSibling
      if (!previousInput) return
      previousInput.focus()
    }

    function handleArrowRight(e) {
      const nextInput = e.target.nextElementSibling
      if (!nextInput) return
      nextInput.focus()
    }

    form.addEventListener('input', handleInput)
    inputs[0].addEventListener('paste', handlePaste)

    inputs.forEach(input => {
      input.addEventListener('focus', e => {
        setTimeout(() => {
          e.target.select()
        }, 0)
      })

      input.addEventListener('keydown', e => {
        switch (e.keyCode) {
          case KEYBOARDS.backspace:
            handleBackspace(e)
            break
          case KEYBOARDS.arrowLeft:
            handleArrowLeft(e)
            break
          case KEYBOARDS.arrowRight:
            handleArrowRight(e)
            break
          default:
        }
      })
    })
  }
</script>

<style>
    :global(.modal-trial) {
        padding: 40px;
    }

    .divider {
        border-bottom: 1px solid #eff2f7 !important;
    }
</style>

{#if loading}
  <div id="state_loader" class="top-0 opacity-100">
    <div id="state_loader__item"/>
  </div>
{/if}
{#if activeUser}
  <div class="my-5 pt-sm-2">
    <Container>
      <Row>
        <Col lg={12}>
          <div class="text-center mb-4 text-muted">
            <div class="avatar">
              <img src="assets/images/logo-1msg-dark.svg" style="max-width: 100px" alt="logo"
                   class="logo">
            </div>
            <p class="mt-3">All WhatsApp tools, one platform</p>
          </div>
        </Col>
      </Row>
      <Row class="justify-content-center">
        <Col md={8} lg={6} style="max-width: 500px">
          <Card>
            <CardBody style="padding: 36px 55px">
              <div class="p-2">
                <div class="text-center">
                  <div class="avatar-md mx-auto">
                    <div class="avatar-title rounded-circle bg-light">
                      <img src="/assets/images/mailbox.svg" alt="mail box" class="avatar-sm"
                           style="width: 100%; height: 100%"/>
                      <!--  <i class="bx bxs-envelope h1 mb-0 text-primary"/>-->
                    </div>
                  </div>
                  <div class="p-2 mt-4">
                    <h4>{$_('verification.email.label')}</h4>
                    <p>
                      {@html $_('verification.email.hint', {
                        values: {
                          email:
                          activeUser.email
                        },
                      })}
                    </p>
                    <div class="mt-3">
                      <form action="#" on:submit|preventDefault={checkCode}>
                        <div class="d-flex mb-3">
                          <input type="tel" maxlength="1" pattern="[0-9]"
                                 class="form-control text-center m-2" required>
                          <input type="tel" maxlength="1" pattern="[0-9]"
                                 class="form-control text-center m-2" required>
                          <input type="tel" maxlength="1" pattern="[0-9]"
                                 class="form-control text-center m-2" required>
                          <input type="tel" maxlength="1" pattern="[0-9]"
                                 class="form-control text-center m-2" required>
                        </div>
                        <div class="mt-3">
                          <button class="btn btn-success w-md"> {$_('confirm')}
                            {#if saving}
                              <Spinner class="ssm"/>
                            {/if}
                          </button>
                        </div>
                      </form>

                    </div>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
          <div class="mt-5 text-center">
            <p>
              {$_('verification.email.problem')}
              <button type="button" on:click={resend} class="fw-medium text-primary border-0 p-0 bg-transparent text-start">
                {$_('resend')}
              </button>
            </p>
            <button type="button" class="fw-medium text-primary mt-3 border-0 p-0 bg-transparent text-start" on:click={handleLogOut}>
              {$_('logout')}
            </button>
          </div>
        </Col>
      </Row>
    </Container>
  </div>

{/if}

<Modal autoFocus={true} backdrop={"static"} centered data-toggle="modal" fade={true} isOpen={trialModal} role="dialog" scrollable size="md">
  <ModalBody class="modal-trial">
    <div>
      <div class="text-center">
        <img alt="ready to start" src="assets/images/rocket-screen.svg"/>
        <div class="mt-3">
          <strong class="fg-bs-gray-700 font-size-20">{@html $_('trial_modal.title')}</strong>
        </div>
        <div class="py-4 position-relative divider">
          <div class="mb-4">{$_('trial_modal.text1')}</div>
          <Link class="btn btn-lg btn-success mb-1" to="/channel/{channelId}"  type="button">
            {$_("trial_modal.btn1")}
          </Link>
          <div class="position-absolute top-100 start-50 translate-middle text-secondary font-size-14 p-1 bg-white">{$_('or')}</div>
        </div>
        <div class="py-4 divider">
          <div class="mb-3 fw-500">{@html $_('trial_modal.text2')}</div>
          <div class=" fade show alert alert-info d-flex mb-3"
               role="alert"><i class="mdi mdi-alert-circle-outline me-2"></i>
            <div>{@html $_('trial_modal.warn')}</div>
          </div>
          <button type="button" class="btn btn-lg btn-success" on:click={acceptTrial}>
            {$_("trial_modal.btn2")}
          </button>
        </div>
        <div class="pt-4 ">
          <div class="mb-4 text-secondary">{$_('trial_modal.text3')}</div>
          <a class="btn btn-lg btn-outline-success" href="https://nut.sh/ell/forms/350938/ylthvf"
             type="button">
            {$_("trial_modal.btn3")}
          </a>
        </div>
      </div>
    </div>
  </ModalBody>
</Modal>
