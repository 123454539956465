import {appClient} from "../store";
import {unionBy} from "lodash/array";
import natsClient from "./NatsClient";

import {get, writable} from "svelte/store";
import {eventTracking} from "../utils";
import {
  MESSAGE_CONTENT_TYPE_LOADING,
  MESSAGE_CONTENT_TYPE_TEXT,
  MESSAGE_DIRECTION_SENT,
  MESSAGE_STATUS_NEW
} from "../constants";

export default class MessagesDAO {
  /**
   * Represents a chat message.
   * @typedef {Object} ChatMessage
   * @property {number} id - The unique identifier for the message.
   * @property {number} bot - The identifier of the bot associated with the message.
   * @property {BotUsers} bot_user - Information about the bot user who sent the message.
   * @property {number} chat - The identifier of the chat where the message was sent.
   * @property {string} id_in_messenger - The unique identifier for the message in the messenger.
   * @property {string} ts_in_messenger - The timestamp of the message in the messenger.
   * @property {string} reply_id_in_messenger - The unique identifier for the replied message in the messenger.
   * @property {string} application - The application associated with the message.
   * @property {number} status - The status of the message.
   * @property {number} direction - The direction of the message.
   * @property {number} content_type - The content type of the message.
   * @property {string} text - The text content of the message.
   * @property {string} image_url - The URL of an associated image.
   * @property {string} file_url - The URL of an associated file.
   * @property {string} video_url - The URL of an associated video.
   * @property {number} product - The identifier of the associated product.
   * @property {Step} step - Information about the step in the chat flow.
   * @property {number} broadcast - The identifier of the broadcast message.
   * @property {string} created_at - The creation timestamp of the message.
   * @property {string} updated_at - The last update timestamp of the message.
   * @property {string} profile_id - The profile identifier.
   * @property {Object} template_data - Additional template data associated with the message.
   * @property {string} waba_template_id - The template identifier for WhatsApp Business API.
   */

  /**
   * Represents a step in a chat flow.
   * @typedef {Object} Step
   * @property {number} id - The unique identifier for the step.
   * @property {string} shop_id - The identifier of the shop associated with the step.
   * @property {Flow} flow - Information about the flow that contains this step.
   * @property {string} name - The name of the step.
   */

  /**
   * Represents a chat flow.
   * @typedef {Object} Flow
   * @property {number} id - The unique identifier for the flow.
   * @property {string} shop_id - The identifier of the shop associated with the flow.
   * @property {string} name - The name of the flow.
   */


  messagesStore;
  messagesSendingStore;
  /**
   * @type {boolean} indicate wether currently mesages are loading
   */
  messagesloading;

  pollInterval = 10000;
  messagesLimitPerLoad = 20;

  /**
   * @type {Chat|ChatMessageSearch} chat
   */
  chat;
  interval;
  last_timestamp;
  first_timestamp;
  channel;

  natsStatusSub;
  natsStatus;

  /**
   *
   * @param {Chat|ChatMessageSearch} chat
   */
  constructor(chat) {
    this.chat = chat
    this.initStores();
  }

  /**
   *
   * @param {SendMessagePayload} data
   */
  #composeFakeMessage(data) {
    const time = new Date().getTime() / 1000
    let fakeId = `${time}${JSON.stringify(data)}`;
    if (fakeId.length > 16) fakeId = fakeId.slice(0, 16);
    /**
     *
     * @type {ChatMessage}
     */
    const newMessage = {
      "id": fakeId,
      "image_url": null,
      "template_data": null,
      "file_url": null,
      "waba_template_id": null,
      "updated_at": time,
      "broadcast": null,
      "text": data.text,
      "product": null,
      "application": null,
      "created_at": time,
      "bot_user": appClient.currentUserOnetouch,
      "video_url": null,
      "status": MESSAGE_STATUS_NEW,
      "direction": MESSAGE_DIRECTION_SENT,
      "step": null,
      "chat": this.chat.id,
      "profile_id": appClient.currentUserOnetouch?.id,
      "ts_in_messenger": time,
      "reply_id_in_messenger": "",
      "content_type": !data.file_url && !data.lng && !data.image_url && !data.video_url && !data.voice_url ? MESSAGE_CONTENT_TYPE_TEXT : MESSAGE_CONTENT_TYPE_LOADING,
      "reply_name": "",
      "reply_text": ""
    }
    this.prepareMessages([newMessage])
    return fakeId
  }

  //переделать на бридж типаж как только натс научится пушить запросы через сокенты на новые данные
  checkAndSetReceivingMessagesType(oldNatsStatus, newNatsStatus) {
    if (oldNatsStatus) {
      this.unsubscribeWithNats();
    } else {
      this.unsubscribeWithHttp();
    }
    if (newNatsStatus) {
      this.subscribeWithNats();
    } else {
      this.subscribeWithHttp();
    }
  }

  clearMessages() {
    this.messagesStore.set([])
    this.last_timestamp = null;
    this.first_timestamp = null;
  }

  getCommonFilters(down = true) {
    let filters = {
      limit: this.messagesLimitPerLoad,
      chat: this.chat.id,
    };
    if (down) {
      if (this.last_timestamp) {
        filters['ts_in_messenger__gt'] = this.last_timestamp;
        filters['ordering'] = 'ts_in_messenger';
      }
    } else {
      if (this.first_timestamp)
        filters['ts_in_messenger__lt'] = this.first_timestamp;
    }

    return filters
  }

  getMessages(directionDown = true) {
    this.messagesloading.set(true);
    return appClient.getMessages(this.getCommonFilters(directionDown), this.channel).then(({result}) => {
      result?.results && this.prepareMessages(result.results)
      this.messagesloading.set(false);
      return result
    })
  }

  getNatsSubKey = () => {
    return `messagesDao.${this.chat.id}`
  }

  //сюда должна вкладываться команда когда мы захотим глобальные сторы использовать или испльзовать кеш
  initStores() {
    this.messagesStore = writable([]);
    this.messagesloading = writable(false);
  }

  prepareMessages(data) {
    if (data?.[0]?.chat === this.chat.id) {
      let localStoreMessages = get(this.messagesStore);
      for (let i = 0; i < data.length; i++) {
        if(data[i].pregenerated_local_id){
          for (let j = 0; j < localStoreMessages.length; j++) {
            if(localStoreMessages[j].id === data[i].pregenerated_local_id){
              localStoreMessages[j] = data[i];
              data.splice(i,1)
              break;
            }
          }
        }
      }
      let newMessages = unionBy(data,localStoreMessages , item => item.id);
      newMessages.sort((left, right) => right?.ts_in_messenger - left?.ts_in_messenger);
      this.last_timestamp = newMessages[0]?.ts_in_messenger;
      this.first_timestamp = newMessages[newMessages.length - 1]?.ts_in_messenger;
      this.messagesStore.set(newMessages)
    }
  }

  prepareNatsData(subject, data) {
    if (subject.indexOf('messages') !== -1)
      this.prepareMessages([data])
  }

  async sendFileMessage(data, type, context) {
    if (!this.chat?.id || !data) {
      return Promise.reject(false);
    }
    return appClient.sendMessage(
      this.chat.id,
      data,
      type,
      this.#composeFakeMessage(data),
      context
    ).then(data => {
      eventTracking('inbox_triggered_action_chat', {action_type: 'send_outgoing_message'});
      return data
    }, err => {
      return err
    })
  }

  async sendTemplateMessage(template_id, template_data, channel, context) {
    //hack to compose proper loading message
    let pregenerated_local_id = this.#composeFakeMessage({text:'',image_url:'1'})
    return appClient.sendWabaTemplateMessage(this.chat.id, template_id, template_data, channel,pregenerated_local_id, context).then(data => {
      return data
    }, err => {
      return err
    })
  }

  async sendTextMessage(text, contentType = 'text', context) {
    const trimValue = String(text).trim();
    if (!this.chat?.id || !trimValue) {
      return Promise.reject(false);
    }
    const payload = {
      text: trimValue
    };
    return appClient.sendMessage(
      this.chat.id,
      payload,
      contentType,
      this.#composeFakeMessage(payload),
      context
    ).then(data => {
      if (contentType === 'note') {
        eventTracking('inbox_triggered_action_chat', {action_type: 'send_note'});
      } else {
        eventTracking('inbox_triggered_action_chat', {action_type: 'send_outgoing_message'});
      }
      return data
    }, err => {
      return err
    })
  }

  startListeningForNewMessages(channel) {
    this.channel = channel;
    this.natsStatus = get(natsClient.getWatchingStore());
    this.natsStatusSub = natsClient.getWatchingStore().subscribe(status => {
      this.checkAndSetReceivingMessagesType(this.natsStatus, status);
      this.natsStatus = status;
    })
//если это не был поиск
    if (this.chat.message_id === undefined) {
      this.getMessages()
    } else {
      this.first_timestamp = this.chat.last_message_time + 1
      this.last_timestamp = this.chat.last_message_time - 1
      this.getMessages(false)
    }

    this.checkAndSetReceivingMessagesType(this.natsStatus, this.natsStatus);
  }

  stopListeningForNewMessages() {
    this.unsubscribeWithNats();
    this.unsubscribeWithHttp();
    try {
      this.natsStatusSub && this.natsStatusSub()
    } catch (e) {
    }
  }

  subscribeWithHttp = () => {
    this.interval = setInterval(() => {
      this.getMessages()
    }, this.pollInterval)
  }

  subscribeWithNats = () => {
    natsClient.addHandler({id: this.getNatsSubKey(), handleObject: this})
  }

  unsubscribeWithHttp = () => {
    this.interval && clearInterval(this.interval)
  }

  unsubscribeWithNats = () => {
    natsClient.deleteHandler(this.getNatsSubKey())
  }

}
