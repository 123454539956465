<script>
  import {
    Container,
    Row,
    Col,
    Button,
    Card,
    CardHeader,
    CardBody,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Modal,
    ModalHeader, TabContent,
    TabPane,
    ModalBody
  } from "sveltestrap";
  import {Link, navigate} from "svelte-routing";
  import {onMount, onDestroy} from "svelte";
  import {paymentProfiles, channels, projects, updateChannels, layoutLoad, user} from "../../helpers/store";
  import {_} from "svelte-i18n";
  import {timer} from "../../helpers/utils";
  import AddPaymentProfileModal from "../../Components/Modals/PaymentProfileAddModal.svelte";
  import PaymentProfileCard from "./PaymentProfileCard.svelte";

  // export let params;
  // export let location;
  let profileModal = false;
  let profileModalType;
  let activeTab;
  let authStatus, activeUser, phone, userChannels, channel = false;
  let projectsLocal = {};

  let paymentProfilesLocal = {'balance': [], 'subscription': []};
  onMount(  () => {
    activeTab = "1";
  });

  onDestroy(() => {
    unsub();
    unsub1();
    unsub2();
  });

  const unsub = paymentProfiles.subscribe(async (value) => {
    paymentProfilesLocal = Array.isArray(value) && value.length ? value.reduce(
      (result, item) => {
        if (item.hasOwnProperty('type')) {
          if (!result[item.type]) result[item.type] = []
          result[item.type].push(item);
        }
        return result;
      },
      {'balance': [], 'subscription': []}
    ) : {'balance': [], 'subscription': []};
  });

  const unsub2 = projects.subscribe(async (value) => {
    projectsLocal = Array.isArray(value) && value.length ? value.reduce(
      (result, item) => {
        if (item.hasOwnProperty('paymentProfileId')) {
          if (!result[item.paymentProfileId]) result[item.paymentProfileId] = []
          result[item.paymentProfileId].push(item);
        }
        return result;
      },
      {}
    ) : {};


  });

  const unsub1 = user.subscribe(async (value) => {
    activeUser = value;
  });

  const setOrderModal = (status, type = 'balance') => {
    profileModalType = type;
    profileModal = status;
  };




</script>
{#if activeUser}
  <AddPaymentProfileModal
      {activeUser}
      profileType={profileModalType}
      show={profileModal}
      onCloseClick={() => setOrderModal(false)}

  />
{/if}


<div class="page-content">
  <Container fluid>
    <div class="d-flex align-items-center justify-content-between">
      <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
        <li class="nav-item" role="presentation">
          <button class={activeTab==='1'?' nav-link active':'nav-link'}
                  on:click={()=>{activeTab='1'}}
                  type="button" role="tab"
          >Оплата
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button class="nav-link {activeTab==='2'?'active':''}"
                  on:click={()=>{activeTab='2'}}
                  type="button" role="tab" >История  платежей
          </button>
        </li>
      </ul>
      <div>
        <Button color="success" on:click={()=>{setOrderModal(true,'subscription')}}><i class="bx bx-plus"></i> Добавить
                                                                                                               подписку
        </Button>
        <Button outline color="success" class="ms-3" on:click={()=>{setOrderModal(true,'balance')}}><i
            class="bx bx-plus"></i> Добавить баланс
        </Button>
      </div>
    </div>
    <div class="tab-content" id="pills-tabContent">
      <TabContent >
        <TabPane tabId="1" class="{activeTab === '1' ? 'active': ''}">
          <div class="tab-pane fade show active" id="pills-payment" role="tabpanel"
               aria-labelledby="pills-tab-payment" style="display: block!important;">

            <div class="header-title">Мои подписки</div>
            <Row>
              {#each paymentProfilesLocal['subscription'] as paymentProfile}
                <Col xxl="4" xl="6" lg="12">
                  <PaymentProfileCard projects={projectsLocal[paymentProfile.id]} paymentProfile={paymentProfile}/>
                </Col>
              {/each}

            </Row>
            <div class="header-title">Мои балансы</div>
            <Row>
              {#each paymentProfilesLocal['balance'] as paymentProfile}
                <Col xxl="4" xl="6" lg="12">
                  <PaymentProfileCard projects={projectsLocal[paymentProfile.id]} paymentProfile={paymentProfile}/>
                </Col>
              {/each}
            </Row>

          </div>
        </TabPane>
        <TabPane tabId="2" class="{activeTab === '2' ? 'active': ''}">
          <div class="tab-pane fade" id="pills-history" role="tabpanel" aria-labelledby="pills-tab-history">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci deserunt dolores explicabo iure modi,
            natus non quaerat quas recusandae soluta ut vel voluptate. Esse illo nam pariatur possimus sapiente?
            Velit.
          </div>
        </TabPane>
      </TabContent>
    </div>


  </Container>
</div>
